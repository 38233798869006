// @flow
import React from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import ShopTransferSwitcher from 'components/ShopTransferSwitcher';

const TransferShoesTo = () => {
    return (
        <ViewContentCard title={text.TRANSFER_OF_SHOES_TO}>
            <ShopTransferSwitcher direction="to" />
        </ViewContentCard>
    );
};

export default TransferShoesTo;
