import React, { useContext, useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    makeStyles,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { GraphicContext } from 'pages/Graphic';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    TimePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import { sendAuthAjax } from 'helpers/authorization';
import styled from 'styled-components/macro';
import { selectUser } from 'context/features/user/userSlice';
import * as text from 'constants/strings';
import { findShopById } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import { delay, map, some } from 'lodash';

const CustomMenuItem = styled(MenuItem)`
    color: ${({ fontcolor }) => fontcolor && fontcolor};
`;

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    showTimePickersCheckbox: {
        paddingBottom: '11px',
    },
}));

const GraphicPeriodDialog = () => {
    const {
        startDate,
        periodDialogOpen,
        handlePeriodDialogClose,
        selectEmployee,
        selectDate,
        setUpdate,
        graphicTypes,
        employeeTimeSheet,
        showTimePickers,
        setShowTimePickers,
        showWarehouseTimePickers,
        setShowWarehouseTimePickers,
        employeeOriginShopName,
    } = useContext(GraphicContext);
    const classes = useStyles();
    const [data, setData] = useState({
        dateFrom: null,
        dateTo: null,
        type: '',
        newStartWorkTime: null,
        newEndWorkTime: null,
        newStartWorkWarehouse: null,
        newEndWorkWarehouse: null,
        changeWorkTimes: false,
    });

    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const usersShop = findShopById(shops, user.shopId);
    const isGranted = useIsGranted();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);
    const isOtherWorkPlace =
        data.type === text.WAREHOUSE ||
        data.type === text.OFFICE ||
        data.type === text.TRAINING;

    const hasSameShopAsDirector =
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
        usersShop &&
        data &&
        ((employeeOriginShopName === usersShop.name &&
            some(shops, (shop) => shop.name === data.type)) ||
            data.type === usersShop.name);

    const enableWarehouseEdit =
        data.newStartWorkTime && data.newStartWorkTime && !isOtherWorkPlace;

    const additionalPermsForPrzejscieDirector =
        usersShop &&
        usersShop.name === text.shopName.PRZEJSCIE &&
        data.type === text.shopName.RYNEK;

    const canSetWorkHours =
        isGranted.hasOneOfRoles.execute([
            text.ROLE_ADMIN_CODE,
            text.ROLE_MANAGER_CODE,
        ]) &&
        moment(data.dateFrom).isSameOrBefore(moment()) &&
        data?.type &&
        (isOtherWorkPlace ||
            some(shops, (shop) => shop.name === data.type) ||
            additionalPermsForPrzejscieDirector);

    const isAllowedToEditForDirector =
        moment(data.dateFrom).isSameOrBefore(moment()) &&
        isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
        (isOtherWorkPlace || hasSameShopAsDirector);
    const graphicTypesShops = shops.filter(
        (shop) =>
            isGranted.hasOneOfRoles.execute([
                text.ROLE_ADMIN_CODE,
                text.ROLE_MANAGER_CODE,
            ]) ||
            (isGranted.hasOneOfRoles.execute([text.ROLE_DIRECTOR_CODE]) &&
                (shop.id === user.shopId ||
                    shop.name === text.WAREHOUSE ||
                    (usersShop.name === text.shopName.PRZEJSCIE &&
                        shop.name === text.shopName.RYNEK))) ||
            data?.type === shop.name
    );

    const handleClickSave = async () => {
        const payload = {
            ...data,
            dateFrom: moment(data.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(data.dateTo).format('YYYY-MM-DD'),
            employeeId: selectEmployee,
            newStartWorkTime: data.newStartWorkTime
                ? moment(data.newStartWorkTime).format('HH:mm:ss')
                : null,
            newEndWorkTime: data.newEndWorkTime
                ? moment(data.newEndWorkTime).format('HH:mm:ss')
                : null,
            newStartWorkWarehouse: data.newStartWorkWarehouse
                ? moment(data.newStartWorkWarehouse).format('HH:mm:ss')
                : null,
            newEndWorkWarehouse: data.newEndWorkWarehouse
                ? moment(data.newEndWorkWarehouse).format('HH:mm:ss')
                : null,
            changeWorkTimes: showTimePickers || showWarehouseTimePickers,
        };

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('post', '/timesheets', payload);
            dispatch(
                addAlert({
                    content: 'Poprawnie zapisano',
                    type: 'success',
                    onClose: () => {},
                })
            );

            if (response.data.scheduleInfo) {
                delay(() => {
                    dispatch(
                        addAlert({
                            content: response.data.scheduleInfo,
                            type: 'info',
                        })
                    );
                }, 3000);
            }

            handlePeriodDialogClose();
            setUpdate(Date.now());
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        setData({
            ...data,
            dateFrom: selectDate || moment(startDate).startOf('week'),
            dateTo: selectDate || moment(startDate).endOf('week'),
            newStartWorkTime: employeeTimeSheet
                ? employeeTimeSheet.startDate
                : null,
            newEndWorkTime: employeeTimeSheet
                ? employeeTimeSheet.endDate
                : null,
            newStartWorkWarehouse: employeeTimeSheet
                ? employeeTimeSheet.startDateWarehouse
                : null,
            newEndWorkWarehouse: employeeTimeSheet
                ? employeeTimeSheet.endDateWarehouse
                : null,
            type: employeeTimeSheet ? employeeTimeSheet.type : '',
        });
    }, [selectDate]);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Dialog
                maxWidth={false}
                open={periodDialogOpen}
                onClose={handlePeriodDialogClose}
                style={{ maxHeight: '90vh' }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Ustaw grafik
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={4}>
                            <KeyboardDatePicker
                                disabled={!!selectDate}
                                label="Od"
                                fullWidth
                                variant="inline"
                                format="YYYY-MM-DD"
                                margin="normal"
                                value={data.dateFrom}
                                onChange={(dateFrom) =>
                                    setData({ ...data, dateFrom })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <KeyboardDatePicker
                                disabled={!!selectDate}
                                label="Do"
                                fullWidth
                                variant="inline"
                                format="YYYY-MM-DD"
                                margin="normal"
                                value={data.dateTo}
                                onChange={(dateTo) =>
                                    setData({ ...data, dateTo })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl
                                fullWidth
                                className={classes.formControl}
                                margin="normal"
                            >
                                <InputLabel shrink id="content-select-label">
                                    Typ
                                </InputLabel>
                                <Select
                                    labelId="content-select-label"
                                    value={data.type}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            type: e.target.value,
                                        })
                                    }
                                    displayEmpty
                                >
                                    <MenuItem value="">-------</MenuItem>
                                    {map(graphicTypes, (type) => (
                                        <CustomMenuItem
                                            key={type.id}
                                            value={type.value}
                                            fontcolor={
                                                type.additional_data.color
                                            }
                                        >
                                            {type.value}
                                        </CustomMenuItem>
                                    ))}
                                    {map(graphicTypesShops, (shop) => (
                                        <CustomMenuItem
                                            key={shop.id}
                                            value={shop.name}
                                            fontcolor={shop.color}
                                            style={{
                                                color:
                                                    shop.name === 'Krupówki'
                                                        ? '#000'
                                                        : shop.color,
                                            }}
                                        >
                                            {shop.name}
                                        </CustomMenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {!!selectDate &&
                        (canSetWorkHours ||
                            (data.newStartWorkTime &&
                                (hasPermissions || hasSameShopAsDirector)) ||
                            isAllowedToEditForDirector ||
                            additionalPermsForPrzejscieDirector) && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showTimePickers}
                                                onChange={() =>
                                                    setShowTimePickers(
                                                        !showTimePickers
                                                    )
                                                }
                                                name="checkbox"
                                                color="primary"
                                                className={
                                                    classes.showTimePickersCheckbox
                                                }
                                            />
                                        }
                                        label="Zmień godziny pracy"
                                    />
                                </Grid>
                                {showTimePickers && (
                                    <Grid item xs={12} lg={6}>
                                        <TimePicker
                                            ampm={false}
                                            disabled={
                                                !hasPermissions &&
                                                !hasSameShopAsDirector &&
                                                !isAllowedToEditForDirector &&
                                                !additionalPermsForPrzejscieDirector
                                            }
                                            value={data.newStartWorkTime}
                                            onChange={(newStartWorkTime) =>
                                                setData({
                                                    ...data,
                                                    newStartWorkTime: newStartWorkTime.set(
                                                        'seconds',
                                                        0
                                                    ),
                                                })
                                            }
                                            cancelLabel="Anuluj"
                                            label="Godzina rozpoczęcia"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                )}
                                {showTimePickers && (
                                    <Grid item xs={12} lg={6}>
                                        <TimePicker
                                            ampm={false}
                                            disabled={
                                                !hasPermissions &&
                                                !hasSameShopAsDirector &&
                                                !isAllowedToEditForDirector &&
                                                !additionalPermsForPrzejscieDirector
                                            }
                                            value={data.newEndWorkTime}
                                            onChange={(newEndWorkTime) =>
                                                setData({
                                                    ...data,
                                                    newEndWorkTime: newEndWorkTime.set(
                                                        'seconds',
                                                        0
                                                    ),
                                                })
                                            }
                                            cancelLabel="Anuluj"
                                            label="Godzina zakończenia"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                )}
                                {enableWarehouseEdit && (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        showWarehouseTimePickers
                                                    }
                                                    onChange={() =>
                                                        setShowWarehouseTimePickers(
                                                            !showWarehouseTimePickers
                                                        )
                                                    }
                                                    name="checkbox-warehouse"
                                                    color="primary"
                                                    className={
                                                        classes.showTimePickersCheckbox
                                                    }
                                                />
                                            }
                                            label="Magazyn"
                                        />
                                    </Grid>
                                )}
                                {showWarehouseTimePickers && (
                                    <Grid item xs={12} lg={6}>
                                        <TimePicker
                                            ampm={false}
                                            disabled={
                                                !hasPermissions &&
                                                !hasSameShopAsDirector &&
                                                !isAllowedToEditForDirector &&
                                                !additionalPermsForPrzejscieDirector
                                            }
                                            value={data.newStartWorkWarehouse}
                                            onChange={(newStartWorkWarehouse) =>
                                                setData({
                                                    ...data,
                                                    newStartWorkWarehouse: newStartWorkWarehouse.set(
                                                        'seconds',
                                                        0
                                                    ),
                                                })
                                            }
                                            cancelLabel="Anuluj"
                                            label="Godzina rozpoczęcia"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                )}
                                {showWarehouseTimePickers && (
                                    <Grid item xs={12} lg={6}>
                                        <TimePicker
                                            ampm={false}
                                            disabled={
                                                !hasPermissions &&
                                                !hasSameShopAsDirector &&
                                                !isAllowedToEditForDirector &&
                                                !additionalPermsForPrzejscieDirector
                                            }
                                            value={data.newEndWorkWarehouse}
                                            onChange={(newEndWorkWarehouse) =>
                                                setData({
                                                    ...data,
                                                    newEndWorkWarehouse: newEndWorkWarehouse.set(
                                                        'seconds',
                                                        0
                                                    ),
                                                })
                                            }
                                            cancelLabel="Anuluj"
                                            label="Godzina zakończenia"
                                            fullWidth
                                            margin="normal"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePeriodDialogClose} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleClickSave} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

export default GraphicPeriodDialog;
