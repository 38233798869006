// @flow
import React, { useState, useEffect } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import FormEditShoes from 'components/FormEditShoes';
import { useParams } from 'react-router-dom';
import {
    setIsLoading,
    addAlert,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import { selectUser } from 'context/features/user/userSlice';

const EditShoes = () => {
    const { id } = useParams();
    const [shoesData, setShoesData] = useState(null);
    const dispatch = useDispatch();
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const [shopId, setShopId] = useState(user.shopId || shops[0].id);

    const getShoesData = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/products/${id}/${shopId}`
            );

            const {
                product: {
                    brand,
                    model,
                    code,
                    color,
                    type,
                    primary_price,
                    promotional_price,
                    group,
                    product_type,
                },
                sizes,
            } = response.data;

            setShoesData({
                brand,
                model,
                color,
                code,
                type,
                primary_price,
                promotional_price,
                product_type,
                group,
                sizes,
            });
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getShoesData();
    }, [shopId]);

    const editProductAction = (payload) => {
        return sendAuthAjax('put', `/products/${id}`, payload);
    };

    return (
        <ViewContentCard title={text.CORRECTING_ERRORS}>
            {shoesData !== null && (
                <FormEditShoes
                    shoesData={shoesData}
                    submitAction={(payload) => editProductAction(payload)}
                    shopId={shopId}
                    setShopId={setShopId}
                    isOnErrorsPage={true}
                />
            )}
        </ViewContentCard>
    );
};

export default EditShoes;
