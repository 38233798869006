// @flow
import React, { useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import FormEditShoes from 'components/FormEditShoes';
import { sendAuthAjax } from 'helpers/authorization';
import { useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import { selectShops } from 'context/features/global/globalSlice';

const AddShoes = () => {
    const user = useSelector((state) => selectUser(state));
    const shops = useSelector((state) => selectShops(state));
    const [shopId, setShopId] = useState(user.shopId || shops[0].id);

    const addProductAction = (payload) => {
        return sendAuthAjax('post', '/products', payload);
    };

    return (
        <ViewContentCard title={text.ADDING_SHOES}>
            <FormEditShoes
                isNew
                submitAction={(payload) => addProductAction(payload)}
                shopId={shopId}
                setShopId={setShopId}
            />
        </ViewContentCard>
    );
};

export default AddShoes;
