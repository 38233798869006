import styled from 'styled-components/macro';
import { TableCell, Typography } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)`
    text-transform: uppercase;
    text-align: center;
    background-color: #f5f5f5;
`;

export const CustomTypography = styled(Typography)`
    color: ${({ fontcolor }) => fontcolor};
`;
