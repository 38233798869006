// @flow
import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, TextField, Button } from '@material-ui/core';
import { formatPrice } from 'helpers/global';

type Props = {
    row: any,
    handleClick: Function,
    activeStep: number,
};

const ExchangeTableRow = ({ row, handleClick, activeStep }: Props) => {
    const [size, setSize] = useState('');

    useEffect(() => {
        setSize('');
    }, [activeStep]);

    const handleSubmitEnter = (e, row, size) => {
        if (e.key === 'Enter') {
            handleClick(row, size);
        }
    };

    return (
        <TableRow hover key={row.id}>
            <TableCell align="center">{row.code}</TableCell>
            <TableCell align="center">{row.brand}</TableCell>
            <TableCell align="center">{row.color}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center">{formatPrice(row.primary_price)}</TableCell>
            <TableCell align="center">{formatPrice(row.promotional_price)}</TableCell>
            <TableCell align="center">
                <TextField
                    type="number"
                    label="rozmiar"
                    value={size}
                    onKeyDown={(e) => handleSubmitEnter(e, row.id, size)}
                    onChange={(e) => setSize(e.target.value)}
                />
            </TableCell>
            <TableCell align="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick(row.id, size)}
                >
                    {activeStep === 0 ? 'Przyjmij' : 'Wydaj'}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ExchangeTableRow;
