import { IconButton } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}));

type Props = {
    icon: React.JSX.Element;
    numSelected: number;
    handleClickRemoveSelected: (() => void) | undefined;
};

function EnhancedTableToolbar(props: Props) {
    const classes = useToolbarStyles();
    const { numSelected, icon, handleClickRemoveSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <Typography
                className={classes.title}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
                {`Zaznaczono: ${numSelected} wierszy`}
            </Typography>

            <IconButton onClick={() => handleClickRemoveSelected?.()}>
                {icon}
            </IconButton>
        </Toolbar>
    );
}

export default EnhancedTableToolbar;
