// @flow
import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink, useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { LOGOUT } from 'constants/strings';
import * as text from 'constants/strings';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import {
    selectShops,
    selectMessageCounter,
} from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import styled from 'styled-components/macro';
import { Badge } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useMenuList from 'hooks/useMenuList';

const CustomizeAvatar = styled(Avatar)`
    background-color: ${({ bgcolor }) => bgcolor};
`;

const lightColor = 'rgba(255, 255, 255, 0.7)';

const useStyles = makeStyles((theme) => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    mobileTabs: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    itemActiveItem: {
        opacity: 1,
        position: 'relative',
        '&::before': {
            width: '100%',
            height: '3px',
            content: "''",
            position: 'absolute',
            bottom: '0px',
            backgroundColor: '#fff',
            borderTopLeftRadius: '3px',
            borderTopRightRadius: '3px',
        },
    },
}));

type Props = {
    onDrawerToggle: Function,
};

const Header = (props: Props) => {
    const { onDrawerToggle } = props;
    const classes = useStyles();
    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const userShop = shops.find((shop) => shop.id === user.shopId);
    const messageCounter = useSelector((state) => selectMessageCounter(state));
    const history = useHistory();
    const menuList = useMenuList();

    return (
        <>
            <AppBar position="sticky" elevation={0} color="primary">
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            {user.shopId === null && (
                                <Alert
                                    variant="outlined"
                                    severity="warning"
                                    style={{ color: 'white', border: 'none' }}
                                >
                                    Brak przypisanego sklepu, nie wszystkie
                                    funkcje będą dostępne
                                </Alert>
                            )}
                        </Grid>
                        <Grid item>
                            <Tooltip title={LOGOUT}>
                                <IconButton
                                    to="/logout"
                                    component={NavLink}
                                    color="inherit"
                                >
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                onClick={() =>
                                    history.push('/historia-komunikatow')
                                }
                            >
                                <Badge
                                    badgeContent={messageCounter}
                                    color="secondary"
                                >
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Tooltip
                                title={`Jestes zalogowany na: ${user.username}`}
                            >
                                <IconButton
                                    color="inherit"
                                    className={classes.iconButtonAvatar}
                                >
                                    <CustomizeAvatar
                                        bgcolor={
                                            userShop !== undefined
                                                ? userShop.color
                                                : ''
                                        }
                                        src=""
                                        alt="My Avatar"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar
                component="div"
                className={clsx(classes.mobileTabs, classes.secondaryBar)}
                color="primary"
                position="static"
                elevation={0}
            >
                <Tabs value={false} textColor="inherit">
                    {menuList.map(({ id, path, name, shortMenuVisible }) => {
                        return (
                            shortMenuVisible  && (
                                <Tab
                                    component={NavLink}
                                    textColor="inherit"
                                    label={name}
                                    to={path}
                                    activeClassName={classes.itemActiveItem}
                                    key={id}
                                />
                            )
                        );
                    })}
                </Tabs>
            </AppBar>
        </>
    );
};

export default React.memo<Props>(Header);
