// @flow
import React, { useState, useEffect } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PreviewSaleTable from 'components/OrdersPreviewTable';
import PreviewExchangeTable from 'components/PreviewExchangeTable';
import {
    FormControl,
    Select,
    MenuItem,
    makeStyles,
    Box,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import { findShopById, findColorShopById } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        marginTop: theme.spacing(2),
    },
    table: {
        marginBottom: "2.5rem"
    },
    divider: {
        marginTop: "1rem",
        marginBottom: "1rem",
        height: 2,
        backgroundColor: "#b5b5b5"
    }
}));

const OrdersPreview = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const user = useSelector((state) => selectUser(state));
    const classes = useStyles();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const [shopId, setShopId] = useState(user.shopId || shops[0].id);
    const [hasPermissions, setHasPermissions] = useState(false);
    const [shopsWithAll, setShopsWithAll] = useState([]);
    const isGranted = useIsGranted();
    
    const getPermissions = (): boolean => {
        return isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE, text.ROLE_EMPLOYEE_CODE]);
    }

    const isManager = (): boolean => {
        return isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE]);
    }

    const getShopsWithAll = (shops: array): array => {
        const allShops = [...shops];
        if (isManager()) {
            const item = {
                color: "#ffffff",
                id: 0,
                localization: "Wszystkie",
                name: "Wszystkie",
            };

            allShops.unshift(item);
            setShopId(item.id);
        }
        return allShops;
    }

    const getShopName = (shopId: number): string => {
        const shop = findShopById(shops, shopId);
        if (shop) {
            return shop.name;
        }
        return '';
    }

    const handleSaleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleSaleShopChange = (id: number) => {
        setShopId(id);
    };

    const createPreviewSaleAndExchangeTable = (shopId: number) => {
        return (
            <div key={shopId}>
                <div className={classes.table}>
                    <PreviewSaleTable
                        title={`${getShopName(shopId)} - Podgląd sprzedaży`}
                        hasPermissions={hasPermissions}
                        date={selectedDate}
                        shopId={shopId}
                        shops={shops}
                    />
                </div>
                <div className={classes.table}>
                    <PreviewExchangeTable
                        title={`${getShopName(shopId)} - ${text.EXCHANGE_PREVIEW}`}
                        titleColor={findColorShopById(shops, shopId)}
                        hasPermissions={hasPermissions}
                        date={selectedDate}
                        shopId={shopId}
                    />
                </div>
            </div>
        );
    };
    
    useEffect(() => {
        setHasPermissions(getPermissions());
        setShopsWithAll(getShopsWithAll(shops));
    }, [user, shops]);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <ViewContentCard title={text.SALES_PREVIEW}>
                <Box display="flex">
                    <KeyboardDatePicker
                        variant="inline"
                        format="YYYY-MM-DD"
                        margin="normal"
                        value={selectedDate}
                        onChange={handleSaleDateChange}
                    />
                    {isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]) && (
                        <FormControl className={classes.formControl}>
                            <Select
                                value={shopId}
                                onChange={(e) => handleSaleShopChange(e.target.value)}
                            >
                                {shopsWithAll.map((shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Box>
                {shopId > 0 && createPreviewSaleAndExchangeTable(shopId)}
                {shopId === 0 && (
                    shops.map(item => {
                        if (item.id > 0) {
                            return createPreviewSaleAndExchangeTable(item.id);
                        }
                        return null;
                    })
                )}
            </ViewContentCard>
        </MuiPickersUtilsProvider>
    );
};

export default OrdersPreview;
