// @flow
import React, { useState } from 'react';
import {
    Checkbox,
    FormControlLabel,
    makeStyles,
    Select,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    TimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { cloneDeep, map, range } from 'lodash';
import { reportPeriod, productGroup, shoeModels } from 'constants/global';

const useStyles = makeStyles((theme) => ({
    selectPeriod: {
        marginTop: '10px',
        marginLeft: '10px',
        height: '32px',
    },
    selectCalendar: {
        marginTop: '10px',
        marginRight: '5px',
    },
    cardHeaderOptions: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    datePickersContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    rangeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: '16px',
    },
    rangeContainerItem: {
        whiteSpace: 'nowrap',
        padding: '5px 5px 5px 0px',
    },
    timePickersContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    hoursContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    selectTime: {
        width: '50px',
        margin: '0 10px',
    },
    timeSelectPrefix: {
        display: 'flex',
        alignItems: 'center',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    fontNormal: {
        fontWeight: 'normal',
    },
    input: {
        width: '100px',
        marginTop: '-3px',
        marginLeft: '10px',
        height: '32px',
    },
}));

const SalesDetailFilters = ({
    filters,
    setFilters,
    showShopsSelect = true,
    showHourSelect = true,
    showCurrencySelect = false,
    showMonthOffsetSelect = false,
    showGroupSelect = false,
    showModelSelect = false,
    showDisplayAmountOrQuantity = false,
    showColorInput = false,
}) => {
    const classes = useStyles();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const [colorInput, setColorInput] = useState(filters.color ?? '');

    const handleRangeCheckboxOnChange = (isChecked) => {
        if (isChecked && filters.dateTo > filters.dateFrom) {
            setFilters({
                ...filters,
                rangeCheckbox: isChecked,
            });
        } else {
            setFilters({
                ...filters,
                rangeCheckbox: isChecked,
                dateTo: cloneDeep(filters.dateFrom),
            });
        }
    };

    const handleHoursCheckboxOnChange = (state) => {
        setFilters({
            ...filters,
            hoursCheckbox: state,
        });
    };

    const handleSelectDateFromChange = (event) => {
        if (filters.rangeCheckbox) {
            setFilters({
                ...filters,
                dateFrom: event,
            });
        } else {
            setFilters({
                ...filters,
                dateFrom: event,
                dateTo: event,
            });
        }
    };

    const handleSelectDateToChange = (event) => {
        setFilters({
            ...filters,
            dateTo: event,
        });
    };

    const handleDatePeriodChange = (value) => {
        let from = moment();

        switch (value) {
            case 'lastYear':
                from = from.startOf('year');
                break;
            case 'lastMonth':
                from = from.startOf('month');
                break;
            case 'lastWeek':
                from = from.subtract(7, 'days');
                break;
            default:
                break;
        }

        setFilters({
            ...filters,
            dateFrom: from,
            dateTo: moment(),
            period: value,
        });
    };

    const handleSelectShopChange = (value) => {
        setFilters({
            ...filters,
            shopId: value,
        });
    };

    const handleSelectTimeFromChange = (event) => {
        setFilters({
            ...filters,
            timeFrom: event,
        });
    };

    const handleSelectTimeToChange = (event) => {
        setFilters({
            ...filters,
            timeTo: event,
        });
    };

    const handleCurrencyChange = (event) => {
        setFilters({
            ...filters,
            currency: event,
        });
    };

    const handleMonthOffsetChange = (event) => {
        setFilters({
            ...filters,
            monthOffset: event,
        });
    };

    const handleSelectGroupChange = (event) => {
        setFilters({
            ...filters,
            group: event,
        });
    };

    const handleSelectModelChange = (event) => {
        setFilters({
            ...filters,
            model: event,
        });
    };

    const handleAmountOrQuantityChange = (event) => {
        setFilters({
            ...filters,
            amountOrQuantity: event,
        });
    };

    const handleColorChange = (e) => {
        setColorInput(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setFilters({
                ...filters,
                color: colorInput,
            });
        }
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.cardHeaderOptions}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={filters.rangeCheckbox}
                            onChange={(e) =>
                                handleRangeCheckboxOnChange(e.target.checked)
                            }
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label="Zakres"
                />
                <div className={classes.datePickersContainer}>
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="YYYY-MM-DD"
                        margin="normal"
                        value={filters.dateFrom}
                        onChange={(event) => {
                            handleSelectDateFromChange(event);
                        }}
                        className={classes.selectCalendar}
                    />
                    {filters.rangeCheckbox && (
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            format="YYYY-MM-DD"
                            margin="normal"
                            value={filters.dateTo}
                            onChange={(event) => {
                                handleSelectDateToChange(event);
                            }}
                            className={classes.selectCalendar}
                        />
                    )}
                </div>

                <Select
                    className={classes.selectPeriod}
                    value={filters.period}
                    onChange={(e) => {
                        handleDatePeriodChange(e.target.value);
                    }}
                >
                    <MenuItem value="today">Dzisiaj</MenuItem>
                    <MenuItem value="lastWeek">Ostatnie 7 dni</MenuItem>
                    <MenuItem value="lastMonth">Ostatni miesiąc</MenuItem>
                    <MenuItem value="lastYear">Ostatni rok</MenuItem>
                </Select>
                {showShopsSelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.shopId}
                        onChange={(e) => {
                            handleSelectShopChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Wszystkie sklepy</MenuItem>
                        {shops.map((shop) => (
                            <MenuItem key={shop.id} value={shop.id}>
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                )}
                {showCurrencySelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.currency}
                        onChange={(e) => {
                            handleCurrencyChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Wszystkie waluty</MenuItem>
                        <MenuItem value="PLN">PLN</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                    </Select>
                )}
                {showMonthOffsetSelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.monthOffset}
                        onChange={(e) => {
                            handleMonthOffsetChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Porównaj z danymi wstecz</MenuItem>
                        {map(range(1, 20, 1), (i) => {
                            return <MenuItem value={i}>{i} mc</MenuItem>;
                        })}
                    </Select>
                )}
                {showGroupSelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.group}
                        onChange={(e) => {
                            handleSelectGroupChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Wszystkie grupy</MenuItem>
                        {map(productGroup, (key) => {
                            return <MenuItem value={key}>{key}</MenuItem>;
                        })}
                    </Select>
                )}
                {showModelSelect && (
                    <Select
                        displayEmpty
                        className={classes.selectPeriod}
                        value={filters.model}
                        onChange={(e) => {
                            handleSelectModelChange(e.target.value);
                        }}
                    >
                        <MenuItem value="">Wszystkie modele</MenuItem>
                        {map(shoeModels, (key) => {
                            return <MenuItem value={key}>{key}</MenuItem>;
                        })}
                    </Select>
                )}
                {showDisplayAmountOrQuantity && (
                    <Select
                        className={classes.selectPeriod}
                        value={filters.amountOrQuantity}
                        onChange={(e) => {
                            handleAmountOrQuantityChange(e.target.value);
                        }}
                    >
                        <MenuItem value={reportPeriod.amount}>Kwota</MenuItem>
                        <MenuItem value={reportPeriod.quantity}>Ilość</MenuItem>
                    </Select>
                )}
                {showColorInput && (
                    <TextField
                        fullWidth
                        size="small"
                        value={colorInput}
                        onKeyDown={handleKeyDown}
                        onChange={handleColorChange}
                        label="kolor"
                        variant="standard"
                        className={classes.input}
                    />
                )}
            </div>
            {showHourSelect && (
                <div className={classes.hoursContainer}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filters.hoursCheckbox}
                                onChange={(e) =>
                                    handleHoursCheckboxOnChange(
                                        e.target.checked
                                    )
                                }
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Godziny"
                    />
                    {filters.hoursCheckbox && (
                        <div className={classes.timePickersContainer}>
                            <div className={classes.timeSelectPrefix}>Od</div>
                            <TimePicker
                                ampm={false}
                                views={['hours']}
                                format="HH"
                                value={filters.timeFrom}
                                onChange={(event) => {
                                    handleSelectTimeFromChange(event);
                                }}
                                className={classes.selectTime}
                            />
                            <div className={classes.timeSelectPrefix}>Do</div>
                            <TimePicker
                                ampm={false}
                                views={['hours']}
                                format="HH"
                                value={filters.timeTo}
                                onChange={(event) => {
                                    handleSelectTimeToChange(event);
                                }}
                                className={classes.selectTime}
                            />
                        </div>
                    )}
                </div>
            )}
            <div className={classes.rangeContainer}>
                <div className={classes.rangeContainerItem}>
                    {' '}
                    Wyświetlany zakres :{' '}
                </div>
                <div
                    className={`${classes.rangeContainerItem} ${classes.fontBold}`}
                >
                    {' '}
                    {filters.dateFrom.format('YYYY-MM-DD')}
                    {filters.hoursCheckbox && (
                        <span className={classes.fontNormal}>
                            :{filters.timeFrom.format('HH')}
                        </span>
                    )}{' '}
                </div>
                <div className={classes.rangeContainerItem}> - </div>
                <div
                    className={`${classes.rangeContainerItem} ${classes.fontBold}`}
                >
                    {' '}
                    {filters.rangeCheckbox
                        ? filters.dateTo.format('YYYY-MM-DD')
                        : filters.dateTo.format('YYYY-MM-DD')}
                    {filters.hoursCheckbox && (
                        <span className={classes.fontNormal}>
                            :{filters.timeTo.format('HH')}
                        </span>
                    )}{' '}
                </div>
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default SalesDetailFilters;
