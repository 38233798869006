import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'context/features/user/userSlice';
import saleReducer from 'context/features/sale/saleSlice';
import globalReducer from 'context/features/global/globalSlice';

export default configureStore({
    reducer: {
        user: userReducer,
        sale: saleReducer,
        global: globalReducer,
    },
});
