// @flow
import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Grid,
    makeStyles,
    Tab,
    TextField,
} from '@material-ui/core';
import { addAlert, setIsLoading } from '../context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from '../helpers/authorization';
import ViewContentCard from '../components/Layout/ViewContentCard';
import { Calendar, DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { forEach } from 'lodash';
import * as text from 'constants/strings';
import georgian_pl from 'constants/gregorian_pl';

const useStyles = makeStyles((theme) => ({
    addToDatabase: {
        margin: theme.spacing(5, 0, 0, 0),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    tabBorder: {
        borderBottom: '1px solid',
        borderColor: theme.palette.divider,
    }
}));

const Settings = () => {
    const [settings, setSettings] = useState({
        accountNumber: '',
    });
    const [sundayValues, setSundayValues] = useState([]);
    const [holidayValues, setHolidayValues] = useState([]);
    const [value, setValue] = React.useState('1');
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', /settings/, settings);
            dispatch(
                addAlert({
                    content: 'Zmiany zapisane',
                    type: 'success',
                })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const saveDates = async (e, type) => {
        e.preventDefault();

        dispatch(setIsLoading(true));

        try {
            let dates = type === text.NON_TRADING_SUNDAY_TAG ? sundayValues : type === text.HOLIDAY_TAG ? holidayValues : null;
            if (dates) {
                let datesToSave = {
                    [type]: [],
                };

                forEach(dates, (date) => {
                    if (date instanceof DateObject) {
                        datesToSave[type].push(
                        {       
                            date: date.toDate(),
                        });
                    }
                })

                await sendAuthAjax('post', /settings-dates/, datesToSave);
                dispatch(
                    addAlert({
                        content: 'Zmiany zapisane',
                        type: 'success',
                    })
                );
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getSettings = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', /settings/);

            setSettings(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getSettings();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getSettingsDates = async () => {
        let nonTradingSundays = [];
        let holidays = [];
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', /settings-dates/);
            if (response.data[0]) {
                forEach(response.data[0], (date) => {
                    if (date.type === text.HOLIDAY_TAG) {
                        holidays.push(new DateObject(new Date(date.calendarDate)));
                    } else if (date.type === text.NON_TRADING_SUNDAY_TAG) {
                        nonTradingSundays.push(new DateObject(new Date(date.calendarDate)))
                    }
                });

                setSundayValues(nonTradingSundays);
                setHolidayValues(holidays);
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    }

    useEffect(() => {
        getSettingsDates();
    }, []);
    
    return (
        <ViewContentCard title="Ustawienia">
            <TabContext value={value}>
                <Box className={classes.tabBorder}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Konto bankowe" value="1" />
                        <Tab label="Niedziele niehandlowe" value="2" />
                        <Tab label="Święta" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <form onSubmit={handleSubmit} noValidate>
                        <Container component="main">
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    name="account_number"
                                    label="Numer Konta bankowego"
                                    value={settings.accountNumber}
                                    onChange={(e) =>
                                        setSettings({
                                            accountNumber: e.target.value,
                                        })
                                    }
                                />
                            </Grid>

                            <Button
                                className={classes.addToDatabase}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Zapisz zmiany
                            </Button>
                        </Container>
                    </form>
                </TabPanel>
                <TabPanel value="2">
                    <Calendar
                        multiple
                        sort
                        weekStartDayIndex={1}
                        value={sundayValues}
                        onChange={setSundayValues}
                        plugins={[<DatePanel 
                            style={{
                                width: '170px',
                                padding: '0 10px',
                            }}
                        />]}
                        locale={georgian_pl}
                        format='DD-MM-YYYY'
                    />
                    <Button
                        className={classes.addToDatabase}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={(e) => saveDates(e, text.NON_TRADING_SUNDAY_TAG)}
                    >
                        Zapisz zmiany
                    </Button>
                </TabPanel>
                <TabPanel value="3">
                    <Calendar
                        multiple
                        sort
                        weekStartDayIndex={1}
                        value={holidayValues}
                        onChange={setHolidayValues}
                        plugins={[<DatePanel 
                            style={{
                                width: '170px',
                                padding: '0 10px',
                            }}
                        />]}
                        locale={georgian_pl}
                        format='DD-MM-YYYY'
                    />
                    <Button
                        className={classes.addToDatabase}
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={(e) => saveDates(e, text.HOLIDAY_TAG)}
                    >
                        Zapisz zmiany
                    </Button>
                </TabPanel>
            </TabContext>
        </ViewContentCard>
    );
};

export default Settings;
