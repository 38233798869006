// @flow
import React, {
    useContext,
    useState,
    createContext,
    useEffect,
    useRef,
} from 'react';
import moment from 'moment';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax, sendAuthFetch } from 'helpers/authorization';
import { AxiosError } from 'axios';
import * as text from 'constants/strings';
import SaleTable from 'components/SaleTable';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableCell,
    TableRow,
    Button,
    Grid,
    IconButton,
    makeStyles,
    ButtonGroup,
    Divider,
} from '@material-ui/core';
import ViewContentCard from '../components/Layout/ViewContentCard';
import SearchBar from 'material-ui-search-bar';
import type { TimesheetMonthlySummary } from '../helpers/interface';
import useIsGranted from 'hooks/useIsGranted';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { formatDate, formatNumber } from 'helpers/global';
import { capitalize, filter, isEmpty, includes } from 'lodash';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CustomMonth from 'components/CustomMonth';

moment.locale('pl');

const useStyles = makeStyles((theme: Theme) => ({
    createSummaryDivider: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '0.5rem',
    },
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const GraphicMonthlySummary = () => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const [timesheetSumamriesFiles, setTimesheetSummariesFiles] = useState([]);
    const [phrase, setPhrase] = useState('');
    const ref = useRef(null);
    const isGranted = useIsGranted();
    const classes = useStyles();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_DIRECTOR_CODE,
    ]);
    const [date, setDate] = useState(new Date());
    const getCurrentMonth = () => {
        const date = new Date();
        const month = date.getMonth();
        return month;
    };
    const handleMonthDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleMonthDialogClose = () => {
        setDialogOpen(false);
    };

    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

    const getSummaryData = async (url, showAlert = false) => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthFetch(url);
            if (showAlert) {
                dispatch(
                    addAlert({ content: 'Poprawnie zapisano', type: 'success' })
                );
            }

            const blobby = await response.blob();
            const objectUrl = window.URL.createObjectURL(blobby);

            ref.current.href = objectUrl;
            ref.current.download = 'miesieczne_podsumowanie_grafiku.pdf';
            ref.current.click();

            window.URL.revokeObjectURL(objectUrl);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const generateSummaryData = async (url, showAlert = false) => {
        dispatch(setIsLoading(true));
        dispatch(handleMonthDialogClose);

        try {
            const response = await sendAuthAjax('post', url, { date: date });
            if (showAlert) {
                dispatch(
                    addAlert({
                        content: 'Poprawnie utworzono podsumowanie',
                        type: 'success',
                    })
                );
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
        dispatch(getTimesheetSummariesFiles);
    };

    const handleChangeSearch = (phrase) => {
        setPhrase(phrase);
    };

    const handleClickRemoveSummary = async (id) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/timesheet-summary-files/${id}`);
            setTimesheetSummariesFiles(
                timesheetSumamriesFiles.filter(
                    (inv: TimesheetMonthlySummary) => inv.id !== id
                )
            );

            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
        } catch (err) {
            const error: AxiosError = err;
            const errorText =
                error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getTimesheetSummariesFiles = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/timesheet-summary-files`
            );
            setTimesheetSummariesFiles(response.data);
        } catch (err) {
            const error: AxiosError = err;
            const errorText =
                error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getTimesheetSummariesFiles();
    }, []);

    return (
        <>
            <h3>
                {text.GENERATE_TIMESHEET_MONTHLY_SUMMARY}:{' '}
                {capitalize(moment().format('MMMM')) + ' ' + moment().year()}
            </h3>
            <div className={classes.buttonsContainer}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={async () =>
                        getSummaryData(`/timesheet-monthly-summary`, true)
                    }
                >
                    {text.CREATE}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleMonthDialogOpen}
                >
                    {text.CREATE_FOR_MONTH}
                </Button>
            </div>
            <Dialog
                maxWidth={true}
                open={isDialogOpen}
                onClose={handleMonthDialogClose}
                style={{ maxHeight: '90vh' }}
                className={classes.dialogContent}
            >
                <DialogTitle className={classes.dialogTitle}>
                    Wybierz miesiąc
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Grid item xs={12}>
                        <KeyboardDatePicker
                            fullWidth
                            views={['year', 'month']}
                            openTo="month"
                            label="Date"
                            size="small"
                            format="YYYY-MM"
                            variant="inline"
                            inputVariant="outlined"
                            maxDate={new Date()}
                            value={date}
                            onChange={(e) => setDate(e)}
                            renderMonth={(month, monthComponent) => (
                                <CustomMonth
                                    selectedMonth={selectedMonth}
                                    {...monthComponent.props}
                                    month={month}
                                />
                            )}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleMonthDialogClose} color="secondary">
                        Anuluj
                    </Button>
                    <Button
                        onClick={async () =>
                            generateSummaryData(
                                `/generate-timesheet-summary-file`,
                                true,
                                selectedMonth
                            )
                        }
                        color="primary"
                    >
                        {text.CREATE}
                    </Button>
                </DialogActions>
            </Dialog>
            <Divider className={classes.createSummaryDivider} />

            <ViewContentCard title={text.TIMESHEET_MONTHLY_SUMMARIES}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                        <SearchBar
                            style={{ height: 36, marginBottom: 10 }}
                            value={phrase}
                            onChange={(value) => handleChangeSearch(value)}
                            onRequestSearch={() => 0}
                            placeholder="Wyszukaj podsumowanie"
                            onCancelSearch={() => handleChangeSearch('')}
                        />
                    </Grid>
                </Grid>
                {!isEmpty(timesheetSumamriesFiles) && (
                    <SaleTable
                        headers={filter([
                            text.REMOVE,
                            text.NAME,
                            text.CREATE_DATE,
                            '',
                            (head: string) =>
                                hasPermissions ||
                                (!hasPermissions && head !== text.REMOVE),
                        ])}
                        rowRender={(row: TimesheetMonthlySummary) => (
                            <TableRow hover key={row.id}>
                                {
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() =>
                                                handleClickRemoveSummary(row.id)
                                            }
                                        >
                                            <DeleteOutlineIcon color="error" />
                                        </IconButton>
                                    </TableCell>
                                }
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">
                                    {formatDate(row.created_at)}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.btnGroup}
                                >
                                    <ButtonGroup
                                        style={{ margin: '0px 5px' }}
                                        orientation="vertical"
                                        color="primary"
                                        aria-label="vertical outlined primary button group"
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={async () =>
                                                getSummaryData(
                                                    `/timesheet-summary/${row.id}`
                                                )
                                            }
                                        >
                                            PDF
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        )}
                        rowsData={filter(timesheetSumamriesFiles, (inv) =>
                            includes(inv.name, phrase)
                        )}
                    />
                )}
            </ViewContentCard>
            <a style={{ display: 'none' }} ref={ref} />
        </>
    );
};

export default GraphicMonthlySummary;
