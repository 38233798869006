// @flow
import React, { useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import ComplaintSearchBar from 'components/ComplaintSearchBar';
import {
    TableRow,
    TableCell,
    Grid,
    Button,
    IconButton,
} from '@material-ui/core';
import SaleTable from 'components/SaleTable';
import { useHistory } from 'react-router-dom';
import { complaintStatus } from 'constants/global';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import moment from 'moment';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch } from 'react-redux';

const Complaints = () => {
    const [result, setResult] = useState([]);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClickRemoveComplaint = async (id) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/complaints/${id}`);
            dispatch(
                addAlert({
                    content: 'Poprawnie usunięto reklamacje',
                    type: 'success',
                })
            );
            setResult(result.filter((item) => item.id !== id));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getDayCount = (row) => {
        if (row.consideration_date) {
            return '';
        }
        const endComplaintDate = moment(row.complaint_date).add(14, 'days');

        return `${endComplaintDate.diff(moment(), 'days')} dni`;
    };

    return (
        <ViewContentCard title={text.COMPLAINTS}>
            <Grid item xs={12} md={4} lg={2}>
                <Button
                    fullWidth
                    onClick={() => history.push(`/dodaj-reklamacje`)}
                    type="submit"
                    variant="contained"
                    color="secondary"
                >
                    Dodaj reklamacje
                </Button>
            </Grid>
            <ComplaintSearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTable
                    headers={[
                        text.REMOVE,
                        'nr. paragonu',
                        'imie i nazwisko',
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.SIZE,
                        text.STATUS,
                        text.SHOP,
                        'dni',
                        '',
                    ]}
                    rowRender={(row) => (
                        <TableRow hover key={row.id}>
                            <TableCell align="center">
                                <IconButton
                                    onClick={() =>
                                        handleClickRemoveComplaint(row.id)
                                    }
                                >
                                    <DeleteOutlineIcon color="error" />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                {row.bill_number}
                            </TableCell>
                            <TableCell align="center">{`${row.first_name} ${row.last_name}`}</TableCell>
                            <TableCell align="center">
                                {row.product_code}
                            </TableCell>
                            <TableCell align="center">
                                {row.product_brand}
                            </TableCell>
                            <TableCell align="center">
                                {row.product_color}
                            </TableCell>
                            <TableCell align="center">
                                {row.product_type}
                            </TableCell>
                            <TableCell align="center">
                                {row.size}
                            </TableCell>
                            <TableCell align="center">
                                {complaintStatus[row.status]}
                            </TableCell>
                            <TableCell align="center">
                                {row.shop_name}
                            </TableCell>
                            <TableCell align="center">
                                {getDayCount(row)}
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        history.push(
                                            `/dodaj-reklamacje/${row.id}`
                                        )
                                    }
                                >
                                    Edytuj
                                </Button>
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default Complaints;
