import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Pie } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import SalesDetailFilters from '../SalesDetailFilters';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import { map, keys } from 'lodash';

const useStyles = makeStyles(() => ({
    cardHeader: {
        display: 'block',
    },
}));

const SalesGroupDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [currency, setCurrency] = useState('PLN');
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment().startOf('month'),
        dateTo: moment(),
        period: 'lastMonth',
        shopId: '',
        currency: '',
    });
    const colors = [pink[100], green[200], purple[400], orange[400]];

    const options = {
        responisve: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                bottom: 20,
            },
        },
        radius: window.innerWidth < 768 ? 70 : 120,
    };

    const preparePieData = (responseData) => {
        const labels = keys(responseData.groups);
        const values = map(labels, (key) => responseData.groups[key].quantity);

        const pieData = {
            labels: labels,
            datasets: [
                {
                    label: 'Wartość',
                    data: values,
                    backgroundColor: colors,
                    hoverOffset: 4,
                },
            ],
        };

        setCurrency(responseData.currency);

        return pieData;
    };

    const prepareTableData = (responseData, summaryData) => {
        let prepareData = map(keys(responseData), (key, index) => ({
            group: key,
            quantity: responseData[key].quantity,
            percent: responseData[key].percent,
            amount: responseData[key].amount,
            color: colors[index],
        }));

        prepareData.push({
            group: 'ŁĄCZNIE',
            quantity: summaryData.quantity,
            percent: summaryData.percent,
            amount: summaryData.amount,
            color: '#cecece',
        });

        return prepareData;
    };

    useEffect(() => {
        const getSalesData = async () => {
            if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
                dispatch(
                    addAlert({
                        content: 'Data "do" nie może być mniejsza od daty "od"',
                        type: 'error',
                    })
                );
                return;
            }

            dispatch(setIsLoading(true));
            const searchParams = new URLSearchParams();

            searchParams.append(
                'dateFrom',
                filters.dateFrom.format('YYYY-MM-DD')
            );
            searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));

            if (filters.shopId) {
                searchParams.append('shopId', filters.shopId);
            }

            if (filters.currency) {
                searchParams.append('currency', filters.currency);
            }

            try {
                const responseCurrent = await sendAuthAjax(
                    'get',
                    '/reports/groups',
                    undefined,
                    searchParams
                );

                setData(preparePieData(responseCurrent.data));
                setTableData(
                    prepareTableData(
                        responseCurrent.data.groups,
                        responseCurrent.data.summary
                    )
                );
            } catch (error) {
                const errorText = error.response.data.message
                    ? error.response.data.message
                    : error.response.statusText;
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }
            dispatch(setIsLoading(false));
        };

        getSalesData();
    }, [filters, dispatch]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                        showHourSelect={false}
                        showCurrencySelect={true}
                    />
                }
                title="Zestawienie sprzedaży grup obuwia"
            />
            <Divider />
            <CardContent>
                <Box height={300} position="relative">
                    {data && <Pie data={data} options={options} />}
                </Box>
                <Box mt={2}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Grupa</TableCell>
                                    <TableCell>Szt.</TableCell>
                                    <TableCell>%</TableCell>
                                    <TableCell>Kwota</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData && tableData.length > 0 ? (
                                    map(tableData, (row, index) => (
                                        <TableRow
                                            hover
                                            key={index}
                                            style={{
                                                background:
                                                    index ===
                                                    tableData.length - 1
                                                        ? row.color
                                                        : 'inherit',
                                            }}
                                        >
                                            <TableCell
                                                style={{
                                                    backgroundColor: row.color,
                                                }}
                                            >
                                                {row.group}
                                            </TableCell>
                                            <TableCell>
                                                {row.quantity}
                                            </TableCell>
                                            <TableCell>
                                                {row.percent}%
                                            </TableCell>
                                            <TableCell>
                                                {Intl.NumberFormat('pl-PL', {
                                                    style: 'currency',
                                                    currency: currency,
                                                }).format(row.amount)}
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            Brak danych do wyświetlenia
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesGroupDetail;
