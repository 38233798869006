// @flow
import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import clsx from 'clsx';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { sortSizes, validateNumberValue } from 'helpers/global';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

export const StyledButton = styled(Button)`
    background-color: ${({ bgcolor }) => bgcolor && bgcolor};
    color: #fff;
    & :hover {
        background-color: ${({ bgcolor }) => bgcolor && bgcolor};
        opacity: 0.8;
    }
`;

const useStyles = makeStyles((theme) => ({
    input: {
        marginRight: '10px',
    },
    addSizeBtn: {
        maxHeight: 40,
        marginRight: '10px',
    },
    addMaleBtn: {
        backgroundColor: green.A400,
    },
    addFemaleBtn: {
        backgroundColor: pink.A100,
    },
    addSizeContainer: {
        margin: theme.spacing(2, 0, 1),
    },
    container: {
        marginTop: '5px',
    },
    container2: {
        marginTop: '5px',
        marginBottom: '5px',
    },
}));

type Props = {
    sizes: Array<any>,
    setSizes: Function,
    isAdditionalStandard: boolean
};

const FormAddSizes = React.forwardRef((props: Props, ref) => {
    const {
        sizes,
        setSizes,
        isAdditionalStandard = false
    } = props;

    const classes = useStyles();
    const sizeRef = useRef(null);
    const quantityRef = useRef(null);
    const dispatch = useDispatch();

    const [error, setError] = useState({
        size: '',
        quantity: '',
    });
    const [standards, setStandards] = useState([]);
    const clearForm = () => {
        sizeRef.current.value = '';
        quantityRef.current.value = '1';
    };

    const setFocus = () => {
        sizeRef.current.focus();
    };

    useImperativeHandle(ref, () => ({
        clearForm: () => {
            clearForm();
        },
        setFocus: () => {
            setFocus();
        },
    }));

    const addSizeToCollection = (_size: number, _quantity: number) => {
        const exists = sizes.find((item) => item.size === _size );
        if (exists) {
            setError({
                size: 'Taki rozmiar już istnieje',
                quantity: '',
            });
            return;
        } else {
            const sizesShoes = sizes.concat({
                id: Date.now(),
                size: _size,
                quantity: _quantity,
            });
    
            setSizes(sortSizes(sizesShoes));
        }

        

        setError({
            size: '',
            quantity: '',
        });

        clearForm();
        setFocus();
    };

    const handleAddSizeOnClick = () => {
        const sizeVal = sizeRef.current.value;
        const quantityVal = quantityRef.current.value;
        let sizeError = false;
        let quantityError = false;

        if (!validateNumberValue(sizeVal)) {
            sizeError = true;
        }
        if (!validateNumberValue(quantityVal)) {
            quantityError = true;
        }

        if (quantityError || sizeError) {
            setError({
                size: sizeError && 'Zła wartość',
                quantity: quantityError && 'Zła wartość',
            });
        } else {
            addSizeToCollection(
                parseInt(sizeVal, 10),
                parseInt(quantityVal, 10)
            );
        }
    };

    const handleRemoveSizeOnClick = (id) => {
        setSizes(
            sizes.filter((item) => {
                if (item.id === id) {
                    return false;
                }
                return true;
            })
        );

    };

    const handleOnChange = (id: number, value: number, type: string) => {
        setSizes(
            sizes.map((item) => {
                if (id === item.id) {
                    return { ...item, [type]: parseInt(value, 10) };
                }
                return item;
            })
        );
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            handleAddSizeOnClick();
        }
    };

    const getStandards = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/dictionaries/categories/sizeStandards`
            );
            setStandards(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (isAdditionalStandard) {
            getStandards();
        }
        sizeRef.current.focus();
    }, []);

    return (
        <>
            <Grid
                container
                className={classes.container}
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={6} md={6} lg={isAdditionalStandard ? 3 : 4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="size-input"
                        label="rozmiar"
                        type="number"
                        size="small"
                        className={classes.input}
                        inputRef={sizeRef}
                        error={!!error.size}
                        helperText={error.size}
                        onKeyDown={handleSubmitEnter}
                    />
                </Grid>
                <Grid item xs={6} md={6} lg={isAdditionalStandard ? 3 : 4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="quantity-input"
                        label="ilosc"
                        size="small"
                        type="number"
                        defaultValue={1}
                        className={classes.input}
                        inputRef={quantityRef}
                        error={!!error.quantity}
                        helperText={error.quantity}
                        onKeyDown={handleSubmitEnter}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={isAdditionalStandard ? 2 : 4}>
                    <Button
                        fullWidth
                        onClick={handleAddSizeOnClick}
                        variant="contained"
                        color="secondary"
                        className={classes.addSizeBtn}
                    >
                        Dodaj rozmiary
                    </Button>
                </Grid>
                {isAdditionalStandard && (
                    <>
                        {standards.map((standard) => (
                            <Grid item xs={6} md={3} lg={2} key={standard.id}>
                                <StyledButton
                                    fullWidth
                                    onClick={() =>
                                        setSizes(standard.additional_data.sizes)
                                    }
                                    variant="contained"
                                    bgcolor={standard.additional_data.color}
                                    className={clsx(
                                        classes.addSizeBtn,
                                        classes.addFemaleBtn
                                    )}
                                >
                                    {standard.value}
                                </StyledButton>
                            </Grid>
                        ))}
                    </>
                )}
                <Grid container className={classes.container2}>
                    <Grid item xs={12} md={6} lg={3}>
                        {sizes.length > 0 && (
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                Rozmiar
                                            </TableCell>
                                            <TableCell align="center">
                                                Ilość
                                            </TableCell>
                                            <TableCell align="center" />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sizes.map((row) =>
                                            <TableRow key={row.id}>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.inputField
                                                    }
                                                >
                                                    <TextField
                                                        fullWidth
                                                        disabled
                                                        value={row.size}
                                                        type="number"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    className={
                                                        classes.inputField
                                                    }
                                                >
                                                    <TextField
                                                        fullWidth
                                                        value={
                                                            row.quantity
                                                        }
                                                        onChange={(e) =>
                                                            handleOnChange(
                                                                row.id,
                                                                e.target
                                                                    .value,
                                                                'quantity'
                                                            )
                                                        }
                                                        type="number"
                                                        variant="outlined"
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <IconButton
                                                        onClick={() =>
                                                            handleRemoveSizeOnClick(
                                                                row.id
                                                            )
                                                        }
                                                    >
                                                        <DeleteOutlineIcon color="error" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default FormAddSizes;
