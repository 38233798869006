// @flow
import React, { useState, useEffect } from 'react';
import {
    TableCell,
    TableRow,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    makeStyles,
    Typography,
} from '@material-ui/core';
import * as text from 'constants/strings';
import 'moment/locale/pl';
import moment from 'moment';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { StyledTableCell } from 'styles/components';
import styled from 'styled-components/macro';
import useIsGranted from 'hooks/useIsGranted';
import PreviewExchangeTableRowBlock from './PreviewExchangeTableRowBlock';

const useStyles = makeStyles((theme) => ({
    issued: {
        backgroundColor: theme.palette.primary.light,
        color: '#fff',
    },
    accepted: {
        backgroundColor: theme.palette.secondary.light,
        color: '#fff',
    },
    h6: {
        paddingBottom: '.8rem',
        borderBottom: '1px solid #ccc',
        marginBottom: '2rem',
        marginTop: '1rem',
    },
}));

const CustomTypography = styled(Typography)`
    color: ${({ fontcolor }) => fontcolor && fontcolor};
`;

type Props = {
    date: any,
    shopId: number,
    hasPermissions: boolean,
    title: string,
    titleColor: string,
};

const PreviewExchangeTable = ({
    date,
    shopId,
    hasPermissions,
    title = '',
    titleColor = '',
}: Props) => {
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const isGranted = useIsGranted();

    const canDeleteInPreviewExchange = isGranted.hasOneOfRoles.execute([text.ROLE_EMPLOYEE_CODE, text.ROLE_ADMIN_CODE]);

    const getExchangePreview = async () => {
        if (!moment(date).isValid() || shopId === '') {
            setResult([]);
            return;
        }

        const url = `/exchange/preview?shopId=${shopId}&date=${moment(
            date
        ).format('YYYY-MM-DD')}`;

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', url);

            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getExchangePreview();
    }, [date, shopId]);

    const handleOnClick = async (id) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('delete', `/exchange/${id}`);

            setResult(result.filter((item) => item.exchange_id !== id));
            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <>
            {result.length > 0 && (
                <>
                    {title && (
                        <CustomTypography
                            variant="h6"
                            className={classes.h6}
                            fontcolor={titleColor}
                        >
                            {title}
                        </CustomTypography>
                    )}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {canDeleteInPreviewExchange && <TableCell />}
                                    <TableCell
                                        className={classes.accepted}
                                        colSpan={7}
                                        align="center"
                                    >
                                        Przyjęcie
                                    </TableCell>
                                    <TableCell
                                        className={classes.issued}
                                        colSpan={7}
                                        align="center"
                                    >
                                        Wydanie
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                                <TableRow>
                                    {canDeleteInPreviewExchange && (
                                        <StyledTableCell>
                                            {text.REMOVE}
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell>
                                        {text.CODE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.BRAND}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.COLOR}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.MODEL}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.NORMAL_PRICE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.PROMOTIONAL_PRICE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.SIZE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.CODE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.BRAND}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.COLOR}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.MODEL}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.NORMAL_PRICE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.PROMOTIONAL_PRICE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.SIZE}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {text.HOUR}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((row) => (
                                    <TableRow key={row.exchange_id}>
                                        {canDeleteInPreviewExchange && (
                                            <TableCell align="center">
                                                <IconButton
                                                    onClick={() =>
                                                        handleOnClick(
                                                            row.exchange_id
                                                        )
                                                    }
                                                >
                                                    <DeleteOutlineIcon color="error" />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        <PreviewExchangeTableRowBlock
                                            type="accepted"
                                            row={row}
                                            merged={row.id_accepted === null}
                                        />
                                        <PreviewExchangeTableRowBlock
                                            type="released"
                                            row={row}
                                            merged={row.id_released === null}
                                        />
                                        <TableCell align="center">
                                            {moment(row.exchange_date).format(
                                                'HH:mm:ss'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </>
    );
};

export default PreviewExchangeTable;
