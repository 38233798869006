// @flow
import React, { useState, createContext, useEffect } from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import {
    setIsLoading,
    addAlert,
    selectShopsWithoutWarehouse,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PLN, arabicNumbersMonths, romanianNumbersMonths } from 'constants/global';
import { selectUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useHistory, useParams } from 'react-router-dom';
import InvoiceDataBlock from 'components/InvoiceDataBlock';
import CustomerSelect from 'components/CustomerSelect';
import CustomerDialog from 'components/CustomerDialog';
import EditCustomerDialog from 'components/EditCustomerDialog';
import InvoiceDialog from 'components/InvoiceDialog';
import InvoiceOrdersBlock from 'components/InvoiceOrdersBlock';
import ConfirmDialog from 'components/ConfirmDialog';
import type { ConfigInvoice, ValidateFormMessage } from "../helpers/interface";
import { findShopById } from 'helpers/global';

const useStyles = makeStyles((theme) => ({
    addInvoice: {
        marginBottom: '10px',
    },
    btnGroup: {
        maxWidth: '68px',
    },
    addCustomer: {
        height: '100%',
    },
    addProduct: {
        marginBottom: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

export const AddingInvoiceContext = createContext(null);

const Invoice = () => {
    const dispatch = useDispatch();
    const [allowAddInvoice, setAllowAddInvoice] = useState(true);
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const history = useHistory();
    const classes = useStyles();
    const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
    const [editCustomerDialogOpen, setEditCustomerDialogOpen] = useState(false);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({
        open: false,
        title: 'Uwaga, czy na pewno chcesz zapisać fakture?',
        content: [],
    });
    const [invoiceData, setInvoiceData] = useState({
        number: '',
        dateOfIssue: new Date(),
        dateOfPayment: new Date(),
        dateOfSale: new Date(),
        paymentType: 'cash',
        status: 'paid',
        currency: PLN,
        shopFromId: user.shopId || shops[0].id,
        amount: 0,
        shippingAmount: null,
        isEcommerce: false
    });
    const [invoiceNumberConst, setInvoiceNumberConst] = useState('');
    const [orders, setOrders] = useState([]);
    const [customers, setCustomers] = useState([]);
    const { id } = useParams();

    const getCustomers = async() => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/customers`);
            setCustomers(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getCustomers();
    }, []);

    const addInvoice = async({isCorrectiveInvoice, isCorrectiveNote}: ConfigInvoice) => {
        if (!allowAddInvoice) {
            return;
        }
        setAllowAddInvoice(false);
        const payload = {
            customerId: selectCustomer.id,
            shopId: user.shopId,
            ...invoiceData,
            correctiveInvoice: isCorrectiveInvoice,
            correctiveNote: isCorrectiveNote,
            products: orders,
        };

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax(
                `${id !== undefined ? 'put' : 'post'}`,
                `${id !== undefined ? `/invoices/${id}` : '/invoices'}`,
                payload
            );
            dispatch(
                addAlert({
                    content:
                        id !== undefined
                            ? 'Poprawnie edytowano fakture'
                            : 'Poprawnie dodano fakture',
                    type: 'success',
                })
            );
            history.push('/faktury');
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        setAllowAddInvoice(true);
        dispatch(setIsLoading(false));
    };

    const getInvoice = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', `/invoices/${id}`);
            const {
                status,
                payment_type,
                company_id,
                company_name,
                person_name,
                company_nip,
                currency,
                amount,
                shipping_amount,
                date_of_issue,
                date_of_payment,
                date_of_sale,
                number,
                shop_from_id,
                ecommerce,
            } = response.data.invoice;

            setSelectCustomer({
                id: company_id,
                companyName: company_name,
                personName: person_name,
                nip: company_nip,
            });

            setInvoiceData({
                number,
                dateOfIssue: date_of_issue,
                dateOfPayment: date_of_payment,
                dateOfSale: date_of_sale,
                paymentType: payment_type,
                status,
                currency,
                amount: amount,
                shippingAmount: shipping_amount,
                shopFromId: shop_from_id,
                isEcommerce: ecommerce
            });

            setInvoiceNumberConst(number);

            setOrders(response.data.orders);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const validateForm = (isCorrectiveInvoice: boolean): ValidateFormMessage => {
        if (!selectCustomer) {
            return { type: 'error', message: 'Nie wybrano nabywcy' }
        }
        if (orders.length < 1 && !isCorrectiveInvoice) {
            return { type: 'error', message: 'Brak produktów w fakturze' }
        }

        const warnings = [];

        if (invoiceData.dateOfIssue == null) {
            warnings.push('Nie dodano daty wystawienia. Data utworzenia zostanie ustawiona na dzisiaj.');
        }

        if (id) {
            const splittedInvoiceNumberArray = invoiceData.number.split('/');
            if (splittedInvoiceNumberArray.length !== 4) {
                warnings.push('Numer faktury jest niezgodny ze standardem zapisu')
            }

            const invoiceNumberNumber = splittedInvoiceNumberArray[0];
            const invoiceNumberMonth = splittedInvoiceNumberArray[1];
            const invoiceNumberShopShort = splittedInvoiceNumberArray[2];
            const invoiceNumberYear = splittedInvoiceNumberArray[3];

            let regExp = /^[1-9]{1}[\d]*$/;
            if (!regExp.test(invoiceNumberNumber)) {
                warnings.push('Numer faktury jest niezgodny ze standardem zapisu')
            }
            let allowedMonths = Object.values(arabicNumbersMonths);
            if (selectCustomer.companyName !== null) {
                allowedMonths = Object.values(romanianNumbersMonths);
            }
            if (!allowedMonths.includes(invoiceNumberMonth)) {
                warnings.push('Miesiąc w numerze faktury jest niezgodny ze standardem zapisu')
            }
    
            const allowedShopsShorts = shops.map((shop) => shop.short);
            if (!allowedShopsShorts.includes(invoiceNumberShopShort)) {
                warnings.push('Skrót sklepu w numerze faktury nie odpowiada żadnemu sklepowi')
            }
    
            regExp = /^[\d]{4}$/;
            if (!regExp.test(invoiceNumberYear)) {
                warnings.push('Rok w numerze faktury jest niezgodny ze standardem zapisu')
            }

            const month = new Date(invoiceData.dateOfIssue).getMonth()+1;
            if (+invoiceNumberMonth !== month && romanianNumbersMonths[month] !== invoiceNumberMonth) {
                warnings.push('Miesiąc w numerze faktury jest niezgodny z miesiącem daty wystawienia')
            }

            const shopFrom = findShopById(shops, invoiceData.shopFromId);

            if (shopFrom.short !== invoiceNumberShopShort) {
                warnings.push('Skrót sklepu w numerze faktury jest niezgodny z polem "ze sklepu"');
            }
    
            const year = new Date(invoiceData.dateOfIssue).getFullYear();
            if (+invoiceNumberYear !== year) {
                warnings.push('Rok w numerze faktury jest niezgodny z rokiem daty wystawienia')
            }
        }

        if (warnings.length > 0) {
            return {type: 'warning', message: warnings};
        }

        return {type: 'pass', message: 'ok'};
    };

    const handleClickSave = ({isCorrectiveInvoice, isCorrectiveNote}: ConfigInvoice) => {
        const validateResult = validateForm(isCorrectiveInvoice);
        if (validateResult.type === 'error') {
            dispatch(addAlert({ content: validateResult.message, type: 'error' }));
        } else if (validateResult.type === 'warning') {
            setConfirmDialog({...confirmDialog, open: true, content: validateResult.message});
        } else if (validateResult.type === 'pass') {
            addInvoice({isCorrectiveInvoice, isCorrectiveNote});
        }
    };

    useEffect(() => {
        if (id !== undefined) {
            getInvoice();
        }
    }, []);

    const handleConfirmCancel = () => {
        setConfirmDialog({...confirmDialog, open: false});
    };

    const handleConfirmOk = () => {
        setConfirmDialog({...confirmDialog, open: false});
        addInvoice();
    };

    const handleInvoice = ({isCorrectiveInvoice, isCorrectiveNote}: ConfigInvoice) => {
        handleClickSave({isCorrectiveInvoice, isCorrectiveNote});
    }

    return (
        <ViewContentCard
            title={id !== undefined ? text.EDIT_INVOICE+' nr: '+invoiceNumberConst : text.ADDING_INVOICE}
        >
            <AddingInvoiceContext.Provider
                value={{
                    getCustomers,
                    customers,
                    setCustomers,
                    selectCustomer,
                    setSelectCustomer,
                    invoiceData,
                    setInvoiceData,
                    customerDialogOpen,
                    setCustomerDialogOpen,
                    editCustomerDialogOpen,
                    setEditCustomerDialogOpen,
                    invoiceDialogOpen,
                    setInvoiceDialogOpen,
                    orders,
                    setOrders,
                    user,
                    id,
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12} md={7} lg={7}>
                        <CustomerSelect />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <Button
                            fullWidth
                            disabled={selectCustomer == null}
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => setEditCustomerDialogOpen(true)}
                            className={classes.addCustomer}
                        >
                            Edytuj nabywce
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="secondary"
                            onClick={() => setCustomerDialogOpen(true)}
                            className={classes.addCustomer}
                        >
                            Dodaj nowego nabywce
                        </Button>
                    </Grid>
                    <InvoiceDataBlock />
                </Grid>
                <InvoiceOrdersBlock id={id} />

                <Grid container justify="flex-end" spacing={1}>
                    {!!id &&
                        <>
                            <Grid item xs={6} md={2} lg={1}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleInvoice({isCorrectiveInvoice: true, isCorrectiveNote: false})}
                                >
                                    Wystaw FK
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={2} lg={1}>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => handleInvoice({isCorrectiveInvoice: false, isCorrectiveNote: true})}
                                >
                                    Wystaw NK
                                </Button>
                            </Grid>
                        </>
                    }
                    <Grid item xs={6} md={2} lg={1}>
                        <Button
                            fullWidth
                            onClick={() => history.push('/faktury')}
                            variant="contained"
                            color="secondary"
                        >
                            Anuluj
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2} lg={1}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            onClick={() => handleInvoice(false, false)}
                            color="primary"
                        >
                            Zapisz
                        </Button>
                    </Grid>
                </Grid>

                <CustomerDialog />
                <EditCustomerDialog />
                <InvoiceDialog />
            </AddingInvoiceContext.Provider>
            <ConfirmDialog
                open={confirmDialog.open}
                title={confirmDialog.title}
                content={confirmDialog.content}
                handleCancel={handleConfirmCancel}
                handleOk={handleConfirmOk}
                cancelLabel="Nie"
                okLabel="Tak"
            />
        </ViewContentCard>
    );
};

export default Invoice;
