// @flow
import React, { useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch } from 'react-redux';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core';
import AccordionTable from 'components/AccordionTable';

const useStyles = makeStyles({
    root: {
        marginTop: '8px',
    },
});

const StatementExchange = () => {
    const [result, setResult] = useState({});
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [name, setName] = useState('');
    const [isFree, setIsFree] = useState(false);
    const [hasComplaint, setHasComplaint] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const getStatement = async () => {
        if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
            dispatch(
                addAlert({
                    content: 'Nieprawidłowy przedział czasowy',
                    type: 'error',
                })
            );
            return;
        }
        const from = moment(fromDate).format('YYYY-MM-DD');
        const to = moment(toDate).format('YYYY-MM-DD');

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/exchanges?dateFrom=${from}&dateTo=${to}&name=${name}&isFree=${isFree}&hasComplaint=${hasComplaint}`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSearch = async () => {
        getStatement();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getStatement();
        }
    };

    return (
        <ViewContentCard title={text.STATEMENT_EXCHANGE}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid
                    className={classes.root}
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            fullWidth
                            label="Od"
                            size="small"
                            format="YYYY-MM-DD"
                            variant="inline"
                            inputVariant="outlined"
                            value={fromDate}
                            onChange={(date) => setFromDate(date)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            fullWidth
                            label="Do"
                            size="small"
                            format="YYYY-MM-DD"
                            variant="inline"
                            inputVariant="outlined"
                            value={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <TextField
                            fullWidth
                            size="small"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            label="imię nazwisko"
                            variant="outlined"
                            className={classes.input}
                            onKeyDown={handleSubmitEnter}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={1}>
                        <Button
                            fullWidth
                            onClick={handleClickSearch}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            szukaj
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isFree}
                                    onChange={(e) =>
                                        setIsFree(e.target.checked)
                                    }
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Gratis klient"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hasComplaint}
                                    onChange={(e) =>
                                        setHasComplaint(e.target.checked)
                                    }
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Reklamacja wydana"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <AccordionTable
                            setResult={setResult}
                            result={Object.entries(result)}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default StatementExchange;
