// @flow
import React from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Grid,
} from '@material-ui/core';
import styled from 'styled-components/macro';

const Container = styled.div`
    margin-right: 20px;
`;

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const ConfirmDialog = ({
    open,
    title = '',
    content = [],
    handleCancel,
    handleOk,
    cancelLabel = '',
    okLabel = '',
}) => {
    const classes = useStyles();

    const handleCancelDialog = () => {
        handleCancel();
    };

    const handleOkDialog = () => {
        handleOk();
    };

    const getCancelLabel = () => {
        return cancelLabel ? cancelLabel : 'Anuluj';
    };

    const getOkLabel = () => {
        return okLabel ? okLabel : 'OK';
    };

    return (
        <Dialog
            className={classes.dialogTable}
            maxWidth={false}
            open={open}
            onClose={handleCancelDialog}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {content.map((item) => {
                            return (
                                <React.Fragment>
                                    {item}
                                    <br></br>
                                </React.Fragment>
                            );
                        })}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Container>
                    <Button
                        className="buttonCancel"
                        onClick={handleCancelDialog}
                        color="secondary"
                    >
                        {getCancelLabel()}
                    </Button>
                </Container>
                <Button onClick={handleOkDialog} color="primary">
                    {getOkLabel()}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
