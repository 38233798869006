import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { ChecklistReport, Pagination } from 'helpers/interface';
import { useQuery, UseQueryOptions } from 'react-query';

export const key = 'checklistReportsQuery';
const path = '/checklist-reports';

type Params = {
    order?: string;
    limit?: number;
    offset?: number;
    dateTo?: string;
    dateFrom?: string;
};

const getChecklistReports = async (params: Params) => {
    const { data } = await sendAuthAjax('get', path, undefined, params);
    return data;
};

export default function useChecklistReportsQuery(
    params: Params = {},
    options: UseQueryOptions<Pagination<ChecklistReport[]>, AxiosError> = {}
) {
    return useQuery<Pagination<ChecklistReport[]>, AxiosError>(
        [key, params],
        () => getChecklistReports(params),
        options
    );
}
