import {
    Button,
    ButtonGroup,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { addAlert } from 'context/features/global/globalSlice';
import { Sort } from 'helpers/interface';
import useIsGranted from 'hooks/useIsGranted';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import useChecklistReportsQuery, { key } from 'hooks/useChecklistReportsQuery';
import useChecklistReportsAverageRatingsQuery, { key as checklistReportsAverageRatingsQueryKey } from 'hooks/useChecklistReportsAverageRatingsQuery';
import useRemoveChecklistReportMutation from 'hooks/useRemoveChecklistReportMutation';
import { NavLink } from 'react-router-dom';
import TableLoader from 'components/TableLoader';
import { StyledTableCell } from 'styles/components';
import { map } from 'lodash';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { formatDateInHour } from 'helpers/global';
import ConfirmDialog from 'components/ConfirmDialog';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles(() => ({
    addToAttendanceButton: {
        marginBottom: '10px',
    },
    dateFilterContainer: {
        marginBottom: '10px',
    },
    activeSortOption: {
        textDecoration: 'underline'
    }
}));

type DeleteConfirmDialog = {
    open: boolean;
    title: string;
    reportId?: number;
};

const pageSize = 20;

function Checklist() {
    const queryClient = useQueryClient();
    const isGranted = useIsGranted();
    const classes = useStyles();
    const [sort, setSort] = useState<Sort>({
        key: 'reportDate',
        direction: 'desc',
    });
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState<{
        dateTo: null | Moment;
        dateFrom: null | Moment;
    }>({ dateTo: null, dateFrom: null });

    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<DeleteConfirmDialog>({
        open: false,
        title: 'Uwaga, czy na pewno chcesz usunąć raport',
        reportId: undefined
    });

    const { data, isLoading, isFetching, isSuccess, isError, error } = useChecklistReportsQuery(
        {
            limit: pageSize,
            offset: page * pageSize,
            dateTo: filters?.dateTo
                ? filters?.dateTo?.format('YYYY-MM-DD')
                : undefined,
            dateFrom: filters?.dateFrom
                ? filters?.dateFrom?.format('YYYY-MM-DD')
                : undefined,
            order: sort ? `${sort.key} ${sort.direction}` : undefined,
        },
        { keepPreviousData: true }
    );

    const checklistReportsAverageRatingsQuery = useChecklistReportsAverageRatingsQuery();

    const { mutate: removeChecklistReport } = useRemoveChecklistReportMutation();

    const handleRemove = useCallback((id: number) => {
        removeChecklistReport(id, {
            onSuccess: () => {
                dispatch(
                    addAlert({
                        content: 'Poprawnie usunięto',
                        type: 'success',
                    })
                );

                queryClient.invalidateQueries(key);
                queryClient.invalidateQueries(checklistReportsAverageRatingsQueryKey);
            },
            onError: (err) => {
                const errorText = err?.response?.data?.message
                    ? err?.response?.data?.message
                    : err?.response?.statusText;

                dispatch(addAlert({ content: errorText, type: 'error' }));
            },
        });
    }, []);

    if (isError && error?.response?.data?.message) {
        dispatch(addAlert({ content: error.response.data.message, type: 'error' }));
    }

    const handleDeleteReportBtn = (id: number) => {
        setDeleteConfirmDialog({
            ...deleteConfirmDialog,
            open: true,
            reportId: id,
        });
    };

    const handleDeleteConfirmDialogCancel = () => {
        setDeleteConfirmDialog({
            ...deleteConfirmDialog,
            open: false,
            reportId: undefined,
        });
    };

    const handleDeleteConfirmDialogOk = () => {
        const id = deleteConfirmDialog.reportId;
        setDeleteConfirmDialog({
            ...deleteConfirmDialog,
            open: false,
            reportId: undefined,
        });
        handleRemove?.(id as number)
    }

    const canAddEditDelete = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE
    ]);

    const loading = isLoading || isFetching || checklistReportsAverageRatingsQuery.isLoading || checklistReportsAverageRatingsQuery.isFetching;

    const shouldDisableDate = (date: MaterialUiPickersDate, compareMode: 'isAfter' | 'isBefore', compareTo: null | Moment) => {
        const d1 = moment(date).startOf('day');

        const d2 = compareTo
            ? compareTo.startOf('day')
            : null;

        if (!d2 || d1.isSame(d2)) {
            return false;
        }

        if (compareMode ==='isAfter') {
            return moment(date).isAfter(compareTo);
        } else {
            return moment(date).isBefore(compareTo);
        }
    }

    return (
        <>
            {canAddEditDelete && (
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            className={classes.addToAttendanceButton}
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            to={`/checklista/wypelnij`}
                            component={NavLink}
                        >
                            Wypełnij nową checklistę
                        </Button>
                    </Grid>
                </Grid>
            )}
            <ViewContentCard title={text.CHECKLIST}>
                <Grid
                    container
                    spacing={1}
                    className={classes.dateFilterContainer}
                >
                    <Grid item xs={12} md={2}>
                        <KeyboardDatePicker
                            InputProps={
                                filters.dateFrom
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <IconButton
                                                      size="small"
                                                      edge="end"
                                                      onClick={() => {
                                                          setPage(0);
                                                          setFilters({
                                                              ...filters,
                                                              dateFrom: null,
                                                          });
                                                      }}
                                                  >
                                                      <ClearIcon fontSize="small" />
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }
                                    : undefined
                            }
                            disabled={loading}
                            shouldDisableDate={(date) => shouldDisableDate(date, 'isAfter', filters.dateTo)}
                            fullWidth
                            label="Data od"
                            format="YYYY-MM-DD"
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            value={filters.dateFrom}
                            onChange={(e) => {
                                setPage(0);
                                setFilters({ ...filters, dateFrom: e });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <KeyboardDatePicker
                            shouldDisableDate={(date) => shouldDisableDate(date, 'isBefore', filters.dateFrom)}
                            InputProps={
                                filters.dateTo
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <IconButton
                                                      size="small"
                                                      edge="end"
                                                      onClick={() => {
                                                          setPage(0);
                                                          setFilters({
                                                              ...filters,
                                                              dateTo: null,
                                                          });
                                                      }}
                                                  >
                                                      <ClearIcon fontSize="small" />
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }
                                    : undefined
                            }
                            disabled={loading}
                            fullWidth
                            label="Data do"
                            format="YYYY-MM-DD"
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            value={filters.dateTo}
                            onChange={(e) => {
                                setPage(0);
                                setFilters({ ...filters, dateTo: e });
                            }}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper} style={{ position: 'relative' }}>
                    {loading && <TableLoader />}
                    <Table>
                        <TableHead>
                            <TableRow style={{ overflow: 'hidden' }}>
                                {canAddEditDelete && (
                                    <StyledTableCell>{text.REMOVE}</StyledTableCell>
                                )}
                                <StyledTableCell align="center">
                                    <TableSortLabel 
                                        active={sort !== null}
                                        className={`${sort.key === 'shop' ? classes.activeSortOption : ''}`}
                                        direction={sort?.direction}
                                        onClick={() => {
                                            setSort({
                                                key: 'shop',
                                                direction:
                                                    sort.direction === 'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                                }
                                            );
                                        }}
                                    >
                                        {text.SHOP}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>{text.TURNOVER_ON_INSPECTION_DAY}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <TableSortLabel 
                                        active={sort !== null}
                                        direction={sort?.direction}
                                        className={`${sort.key === 'shopRating' ? classes.activeSortOption : ''}`}
                                        onClick={() => {
                                            setSort({
                                                key: 'shopRating',
                                                direction:
                                                    sort.direction === 'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                                }
                                            );
                                        }}
                                    >
                                        {text.RATING}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>{text.AVERAGE_RATING}</StyledTableCell>
                                <StyledTableCell align="center">
                                    <TableSortLabel 
                                        active={sort !== null}
                                        direction={sort?.direction}
                                        className={`${sort.key === 'reportDate' ? classes.activeSortOption : ''}`}
                                        onClick={() => {
                                            setSort({
                                                key: 'reportDate',
                                                direction:
                                                    sort.direction === 'asc'
                                                        ? 'desc'
                                                        : 'asc',
                                                }
                                            );
                                        }}
                                    >
                                        {text.CREATION_DATE}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isSuccess && checklistReportsAverageRatingsQuery.isSuccess && map(data.data, (item) => (
                                <TableRow key={item.id}>
                                    {canAddEditDelete && (
                                        <TableCell align="center" width={50}>
                                            <IconButton
                                                onClick={() => {
                                                    handleDeleteReportBtn(item.id as number);
                                                }}
                                            >
                                                <DeleteOutlineIcon color="error" />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                    <TableCell align="center">
                                        {item.shop?.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.turnover_on_inspection_day + ' zł'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.shop_rating}
                                    </TableCell>
                                    <TableCell align="center">
                                        {checklistReportsAverageRatingsQuery.data.find(element => element.shop_id === item.shop?.id)?.average_rating}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatDateInHour(item.report_date)}
                                    </TableCell>
                                    <TableCell align="center" width={50}>
                                        <ButtonGroup
                                            style={{ margin: '0px 5px' }}
                                            orientation="vertical"
                                            color="primary"
                                            aria-label="vertical outlined primary button group"
                                            variant="outlined"
                                            size="small"
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                to={`/checklista/pokaz/${item.id}`}
                                                component={NavLink}
                                            >
                                                POKAŻ
                                            </Button>
                                            {canAddEditDelete && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    to={`/checklista/wypelnij/${item.id}`}
                                                    component={NavLink}
                                                >
                                                    Edytuj
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                labelDisplayedRows={({ from, to, count }) =>
                                    `${from}-${to} z ${count}`
                                }
                                count={data?.totalCount || 0}
                                rowsPerPage={pageSize}
                                page={page}
                                onChangePage={(_, page) => {
                                    setPage(page);
                                }}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>
            </ViewContentCard>
            <ConfirmDialog
                open={deleteConfirmDialog.open}
                title={deleteConfirmDialog.title}
                handleCancel={handleDeleteConfirmDialogCancel}
                handleOk={handleDeleteConfirmDialogOk}
                cancelLabel="Nie"
                okLabel="Tak"
            />
        </>
    );
}

export default Checklist; 
