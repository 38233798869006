// @flow
import { useState, useEffect } from 'react';
import { useDispatch, batch } from 'react-redux';
import jwt from 'jwt-decode';
import { sendAuthAjax } from 'helpers/authorization';
import { loginUser, logoutUser } from 'context/features/user/userSlice';
import { setShops, setAllRoles } from 'context/features/global/globalSlice';

const useAuthorization = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const dispatch = useDispatch();
    const token = localStorage.getItem('currentUserToken');

    const verifyToken = async () => {
        if (!token) {
            setIsAuthenticated(false);
            dispatch(logoutUser());
            return;
        }

        let responseStatus = null;
        try {
            const response = await sendAuthAjax('get', `/verify-token`);
            responseStatus = response.status;
        } catch (error) {
            responseStatus = error.response.status;
        }
        if (responseStatus === 200) {
            const {
                username,
                ip,
                shopId,
                shops,
                roles,
                allRoles,
                userId,
            } = jwt(token);

            batch(() => {
                dispatch(
                    loginUser({ username, roles, token, ip, shopId, userId })
                );
                dispatch(setShops({ shops }));
                dispatch(setAllRoles(allRoles));
            });
            setIsAuthenticated(true);
        } else {
            localStorage.removeItem('currentUserToken');
            setIsAuthenticated(false);
            dispatch(logoutUser());
        }
    };

    useEffect(() => {
        verifyToken();
    }, []);

    return isAuthenticated;
};

export default useAuthorization;
