// @flow
import React, { useState, createContext, useEffect } from 'react';
import { Button, makeStyles, Grid } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import {
    selectShopsWithoutWarehouse,
    addAlert,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectUser } from 'context/features/user/userSlice';
import ComplaintDataBlock from 'components/ComplaintDataBlock';
import ComplaintProductBlock from 'components/ComplaintProductBlock';
import ComplaintDialog from 'components/ComplaintDialog';
import { sendAuthAjax } from 'helpers/authorization';

const useStyles = makeStyles((theme) => ({
    addInvoice: {
        marginBottom: '10px',
    },
    btnGroup: {
        maxWidth: '68px',
    },
    addCustomer: {
        height: '100%',
    },
    addProduct: {
        marginBottom: theme.spacing(1),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

export const AddingComplaintContext = createContext(null);

const Complaint = () => {
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const history = useHistory();
    const classes = useStyles();
    const [productsDialogOpen, setProductsDialogOpen] = useState(false);
    const [complaintData, setComplaintData] = useState({
        bill_number: '',
        first_name: '',
        last_name: '',
        city: '',
        street: '',
        post_code: '',
        description: '',
        seller_notes: '',
        customer_request: '',
        status: 'notExamined',
        notification_type: 'byPhone',
        purchase_date: null,
        shop_id: user.shopId || shops[0].id,
        phone_number: '',
        size: '',
    });
    const [product, setProduct] = useState(null);
    const [customers, setCustomers] = useState([]);
    const { id } = useParams();

    const addComplaint = async () => {
        const payload = {
            ...complaintData,
            product_id: product.id,
        };

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax(
                `${id !== undefined ? 'put' : 'post'}`,
                `${id !== undefined ? `/complaints/${id}` : '/complaints'}`,
                payload
            );
            dispatch(
                addAlert({
                    content:
                        id !== undefined
                            ? 'Poprawnie edytowano reklamacje'
                            : 'Poprawnie dodano reklamacje',
                    type: 'success',
                })
            );
            history.push('/reklamacje');
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const validate = () => {
        return product !== null;
    };

    const handleClickSave = () => {
        if (!validate()) {
            dispatch(addAlert({ content: 'Niekompletne dane', type: 'error' }));
            return;
        }
        addComplaint();
    };

    const getComplaint = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', `/complaints/${id}`);

            const {
                product_id,
                product_code,
                product_brand,
                product_model,
                product_color,
                product_type,
                product_primary_price,
                product_promotional_price,
                bill_number,
                first_name,
                last_name,
                city,
                street,
                post_code,
                size,
                description,
                seller_notes,
                customer_request,
                status,
                notification_type,
                shop_id,
                purchase_date,
                phone_number,
            } = response.data;

            setProduct({
                id: product_id,
                code: product_code,
                brand: product_brand,
                model: product_model,
                color: product_color,
                type: product_type,
                primary_price: product_primary_price,
                promotional_price: product_promotional_price,
            });
            setComplaintData({
                bill_number,
                first_name,
                last_name,
                city,
                street,
                post_code,
                size,
                description,
                seller_notes,
                customer_request,
                status,
                notification_type,
                shop_id,
                purchase_date,
                phone_number,
            });
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (id !== undefined) {
            getComplaint();
        }
    }, []);

    return (
        <ViewContentCard
            title={
                id !== undefined ? text.EDIT_COMPLAINT : text.ADDING_COMPLAINT
            }
        >
            <AddingComplaintContext.Provider
                value={{
                    customers,
                    setCustomers,
                    complaintData,
                    setComplaintData,
                    productsDialogOpen,
                    setProductsDialogOpen,
                    product,
                    setProduct,
                    isNew: id === undefined,
                }}
            >
                <ComplaintDataBlock />
                <ComplaintProductBlock />
                <ComplaintDialog />
                <Grid container justify="flex-end" spacing={1}>
                    <Grid item xs={6} md={2} lg={1}>
                        <Button
                            fullWidth
                            onClick={() => history.push('/reklamacje')}
                            variant="contained"
                            color="secondary"
                        >
                            Anuluj
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={2} lg={1}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            onClick={handleClickSave}
                            color="primary"
                        >
                            Zapisz
                        </Button>
                    </Grid>
                </Grid>
            </AddingComplaintContext.Provider>
        </ViewContentCard>
    );
};

export default Complaint;
