// @flow
import React, { useEffect, useRef } from 'react';
import { Button, makeStyles, Grid, TextField } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSaleSearch,
    setSearchParam,
    clearSearchParams,
} from 'context/features/sale/saleSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';

const useStyles = makeStyles(() => ({
    container: {
        margin: '10px 0px',
        width: '100%',
    },
    input: {
        marginRight: '10px',
    },
}));

type Props = { setResult: Function, shopIdToSearch: number, addParams: object };

const SearchBar = ({ setResult, shopIdToSearch, addParams = {} }: Props) => {
    const classes = useStyles();
    const saleSearch = useSelector((state) => selectSaleSearch(state));
    const dispatch = useDispatch();
    const contentInputRef = useRef(null);

    const getProducts = async () => {
        dispatch(setIsLoading(true));
        try {
            const shopId = shopIdToSearch;
            const response = await sendAuthAjax(
                'post',
                `/products/search/shoes`,
                { ...saleSearch, shopId, ...addParams }
            );

            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        dispatch(clearSearchParams());
        contentInputRef.current.focus();
        //setResult([]);
        
        // if (Object.entries(saleSearch).some(([, value]) => value)) {
        //     getProducts();
        // }
    }, []);

    const handleOnClick = (e) => {
        e.preventDefault();
        getProducts();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getProducts();
        }
    };

    return (
        <Grid
            container
            alignItems="center"
            spacing={1}
            className={classes.container}
        >
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.code}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'code',
                                value: e.target.value,
                            })
                        )
                    }
                    label="kod"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                    inputRef={contentInputRef}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.brand}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'brand',
                                value: e.target.value,
                            })
                        )
                    }
                    label="marka"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.color}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'color',
                                value: e.target.value,
                            })
                        )
                    }
                    label="kolor"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                <TextField
                    fullWidth
                    size="small"
                    value={saleSearch.type}
                    onChange={(e) =>
                        dispatch(
                            setSearchParam({
                                type: 'type',
                                value: e.target.value,
                            })
                        )
                    }
                    label="model"
                    variant="outlined"
                    className={classes.input}
                    onKeyDown={handleSubmitEnter}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={1}>
                <Button
                    fullWidth
                    onClick={handleOnClick}
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    szukaj
                </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
                <Button
                    fullWidth
                    onClick={() => {
                        dispatch(clearSearchParams());
                        setResult([]);
                    }}
                    variant="contained"
                    color="primary"
                >
                    wyczyść wyszukiwarke
                </Button>
            </Grid>
        </Grid>
    );
};

export default SearchBar;
