import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import teal from '@material-ui/core/colors/teal';
import orange from '@material-ui/core/colors/orange';
import { keys, map } from 'lodash';
import { reportPeriod } from 'constants/global';
import SalesDetailFilters from '../SalesDetailFilters';
import { getSalesFilters } from './salesReportHelper';

const useStyles = makeStyles(() => ({
    cardHeader: {
        display: 'block',
    },
}));
const colors = [orange[600], teal[500]];

const SalesReportDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState(null);
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment().startOf('month'),
        dateTo: moment(),
        period: 'lastMonth',
        shopId: '',
        currency: '',
        model: '',
        group: '',
        amountOrQuantity: reportPeriod.amount,
    });

    const options = {
        responsive: true,
        cornerRadius: 20,
        layout: { padding: 0 },
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                    },
                    gridLines: {
                        display: true,
                        drawBorder: true,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                        min: 0,
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider,
                    },
                },
            ],
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary,
        },
    };

    useEffect(() => {
        const prepareBarData = (responseData) => {
            const labels = keys(responseData[reportPeriod.current]);

            const currentQuantity = map(
                labels,
                (key) =>
                    responseData[reportPeriod.current]?.[key]?.quantity || 0
            );
            const currentAmount = map(
                labels,
                (key) => responseData[reportPeriod.current]?.[key]?.amount || 0
            );
            const previousQuantity = map(
                labels,
                (key) =>
                    responseData[reportPeriod.previous]?.[key]?.quantity || 0
            );
            const previousAmount = map(
                labels,
                (key) => responseData[reportPeriod.previous]?.[key]?.amount || 0
            );

            let datasets = [];
            if (filters.amountOrQuantity === reportPeriod.quantity) {
                datasets = [
                    {
                        label: 'Ilość obecnie',
                        data: currentQuantity,
                        backgroundColor: colors[0],
                        borderColor: colors[0],
                        borderWidth: 1,
                    },
                    {
                        label: 'Ilość rok poprzedni',
                        data: previousQuantity,
                        backgroundColor: colors[1],
                        borderColor: colors[1],
                        borderWidth: 1,
                    },
                ];
            } else if (filters.amountOrQuantity === reportPeriod.amount) {
                datasets = [
                    {
                        label: 'Wartość obecnie',
                        data: currentAmount,
                        backgroundColor: colors[0],
                        borderColor: colors[0],
                        borderWidth: 1,
                    },
                    {
                        label: 'Wartość rok poprzedni',
                        data: previousAmount,
                        backgroundColor: colors[1],
                        borderColor: colors[1],
                        borderWidth: 1,
                    },
                ];
            }

            const barData = {
                labels,
                datasets,
            };

            return barData;
        };

        const getSalesData = async (searchParams) => {
            dispatch(setIsLoading(true));

            try {
                const responseCurrent = await sendAuthAjax(
                    'get',
                    '/reports/sales',
                    undefined,
                    searchParams
                );

                setData(prepareBarData(responseCurrent.data));
            } catch (error) {
                const errorText =
                    error.response?.data?.message ||
                    error.response?.statusText ||
                    'An error occurred';
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }

            dispatch(setIsLoading(false));
        };

        if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );

            return;
        }

        const searchParams = getSalesFilters(filters);
        getSalesData(searchParams);
    }, [dispatch, filters]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                        showShopsSelect
                        showHourSelect={false}
                        showGroupSelect
                        showModelSelect
                        showDisplayAmountOrQuantity
                        showColorInput
                    />
                }
                title="Wartość zakupów"
            />
            <Divider />
            <CardContent>
                <Box height={400} position="relative">
                    {data && <Bar data={data} options={options} />}
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesReportDetail;
