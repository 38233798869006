// @flow
import React, { useState, memo, useEffect } from 'react';
import {
    Button,
    TextField,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { AxiosError } from "axios";

type Props = {
    rowData: Array<string>,
    updateResult: (id: number, shoperVariantId: number) => void,
};

const ShoperVariantRow = ({
    rowData,
    updateResult,
}: Props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        id: rowData.id,
        productId: rowData.product_id,
        productCode: rowData.code,
        productBrand: rowData.brand,
        productModel: rowData.model,
        productColor: rowData.color,
        productType: rowData.type,
        size: rowData.size,
        shoperVariantId: rowData.shoper_variant_id
    });

    useEffect(() => {
        setData({
            id: rowData.id,
            productId: rowData.product_id,
            productCode: rowData.code,
            productBrand: rowData.brand,
            productModel: rowData.model,
            productColor: rowData.color,
            productType: rowData.type,
            size: rowData.size,
            shoperVariantId: rowData.shoper_variant_id
        });
    }, [rowData]);

    const handleClickRow = async() => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('put', `/product-variants/${data.id}`, {
                shoperVariantId: data.shoperVariantId
            });
            updateResult(data.id, data.shoperVariantId)
            dispatch(
                addAlert({
                    content: `Zapisano dla rozmiaru: ${data.size}`,
                    type: 'success',
                })
            );

        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            handleClickRow();
        }
    };

    return (
        <TableRow hover>
            <TableCell align='center'>{data.productCode}</TableCell>
                <TableCell align='center'>{data.productBrand}</TableCell>
                <TableCell align='center'>{data.productColor}</TableCell>
                <TableCell align='center'>{data.productType}</TableCell>
                <TableCell align='center'>{data.size}</TableCell>
                <TableCell align="center">
                    <TextField
                        type="number"
                        value={data.shoperVariantId !== null ? data.shoperVariantId : ''}
                        onChange={(e) =>
                            setData({
                                ...data,
                                shoperVariantId: (e.target.value === '' || e.target.value === null) ? null : +e.target.value,
                            })
                        }
                        onKeyDown={handleSubmitEnter}
                    />
                </TableCell>
                <TableCell align="center">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => handleClickRow()}
                    >
                        Zapisz
                    </Button>
                </TableCell>
        </TableRow>
    );
};

export default memo<Props>(
    ShoperVariantRow,
    (prev, next) => isEqual(prev, next)
);
