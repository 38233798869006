import axios, { Method } from 'axios';
import { getApiUrl } from 'helpers/global';

export const sendAuthAjax = (method: Method, path: string, data?: any, params?: any) => {
    const token: string = localStorage.getItem('currentUserToken') || '';
    const apiUrl: string = getApiUrl();

    return axios({
        method,
        url: `${apiUrl}${path}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data,
        params
    });
};

export const sendAuthFetch = (path: string) => {
    const token: string = localStorage.getItem('currentUserToken') || '';
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const apiUrl: string = getApiUrl();

    return fetch(`${apiUrl}${path}`, { headers });
};
