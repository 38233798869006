// @flow
import React, { useState, useEffect } from 'react';
import {
    Button,
    makeStyles,
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import {
    setIsLoading,
    addAlert,
    selectShopsWithoutWarehouse,
} from 'context/features/global/globalSlice';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { selectUser } from 'context/features/user/userSlice';
import * as text from 'constants/strings';
import type { Shop } from "../helpers/interface";
import { filterShopsWithoutEcommerce } from "../helpers/global";
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles(() => ({
    container: {
        margin: '10px 0px',
        width: '100%',
    },
    input: {
        marginRight: '10px',
    },
    select: {
        height: '40px',
    },
}));

type Props = { setResult: Function };

const ComplaintSearchBar = ({ setResult }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const isGranted = useIsGranted();
    const [searchParams, setSearchParams] = useState({
        lastName: '',
        billNumber: '',
        isComplaintConsideration: false,
        purchaseDate: null,
        shopId: isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE])
            ? ''
            : user.shopId,
    });

    const getComplaints = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'post',
                `/complaints/search`,
                searchParams
            );

            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleOnClick = (e) => {
        e.preventDefault();
        getComplaints();
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            getComplaints();
        }
    };

    useEffect(() => {
        getComplaints();
    }, []);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid
                container
                alignItems="center"
                spacing={1}
                className={classes.container}
            >
                <Grid item xs={12} md={4} lg={2}>
                    <TextField
                        fullWidth
                        size="small"
                        value={searchParams.lastName}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                lastName: e.target.value,
                            })
                        }
                        label="Nazwisko"
                        variant="outlined"
                        className={classes.input}
                        onKeyDown={handleSubmitEnter}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <TextField
                        fullWidth
                        size="small"
                        value={searchParams.billNumber}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                billNumber: e.target.value,
                            })
                        }
                        label="Nr. paragonu"
                        variant="outlined"
                        className={classes.input}
                        onKeyDown={handleSubmitEnter}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <KeyboardDatePicker
                        size="small"
                        clearLabel="Wyczyść"
                        cancelLabel="Anuluj"
                        inputVariant="outlined"
                        fullWidth
                        className={classes.formControl}
                        variant="dialog"
                        format="YYYY-MM-DD"
                        value={searchParams.purchaseDate}
                        onChange={(date) =>
                            setSearchParams({
                                ...searchParams,
                                purchaseDate: date,
                            })
                        }
                        label="Data zakupu"
                        clearable
                    />
                </Grid>
                {isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE]) && (
                    <Grid item xs={12} md={4} lg={1}>
                        <FormControl
                            size="small"
                            fullWidth
                            className={classes.formControl}
                        >
                            <Select
                                variant="outlined"
                                displayEmpty
                                value={searchParams.shopId}
                                onChange={(e) =>
                                    setSearchParams({
                                        ...searchParams,
                                        shopId: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                                    <MenuItem key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={searchParams.isComplaintConsideration}
                                onChange={(e) =>
                                    setSearchParams({
                                        ...searchParams,
                                        isComplaintConsideration:
                                            e.target.checked,
                                    })
                                }
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Reklamacje rozpatrzone"
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={1}>
                    <Button
                        fullWidth
                        onClick={handleOnClick}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        szukaj
                    </Button>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
};

export default ComplaintSearchBar;
