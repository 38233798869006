import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { useMutation, UseMutationOptions } from 'react-query';

const path = '/checklist-reports/';

const deleteChecklistReport = async (id: number) => {
    const { data } = await sendAuthAjax('delete', path + id);
    return data;
};

export default function useRemoveChecklistReportMutation(
    options: UseMutationOptions<void, AxiosError, number> = {}
) {
    return useMutation<void, AxiosError, number>(deleteChecklistReport, options);
}
