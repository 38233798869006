// @flow
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useQueryClient } from 'react-query';

const Logout = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const setLogs = async () => {
        try {
            await sendAuthAjax('post', '/logout');
        } catch (error) {}
    };

    useEffect(() => {
        setLogs();
        localStorage.removeItem('currentUserToken');
        queryClient.invalidateQueries();

        dispatch(logoutUser());
    }, []);

    return <Redirect to="/" />;
};

export default Logout;
