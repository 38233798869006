import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    paper: {
        overflow: 'hidden',
        width: '100%',
        marginBottom: '20px',
        maxWidth: 'calc(100vw - 49px)',
    },
    bar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    contentWrapper: {
        margin: '10px 16px',
    },
}));

type Props = { children: React.ReactNode; title: string };

function ViewContentCard({ children, title }: Props) {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <AppBar
                className={classes.bar}
                position="static"
                color="default"
                elevation={0}
            >
                <Toolbar>
                    <Typography variant="h6">{title}</Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>{children}</div>
        </Paper>
    );
}

export default ViewContentCard;
