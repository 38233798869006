// @flow
import React, { useState, useEffect, createContext } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    makeStyles,
    Grid,
    ButtonGroup,
} from '@material-ui/core';
import { AxiosError } from "axios";
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { currencySymbol, productOfTheDayDialogModes } from 'constants/global';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { formatDate, formatNumber } from 'helpers/global';
import ProductOfTheDayDialog from 'components/ProductOfTheDayDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import type { ProductOfTheDay as ProductOfTheDayInterface } from "../helpers/interface";
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles(() => ({
    addProductOfTheDay: {
        marginBottom: '10px',
    },
}));

export const ProductOfTheDayContext = createContext(null);

const ProductOfTheDay = () => {
    const [currentProductsOfTheDay, setCurrentProductsOfTheDay] = useState([]);
    const [scheduledProductsOfTheDay, setScheduledProductsOfTheDay] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [dialogConfig, setDialogConfig] = useState({
        visible: false,
        mode: productOfTheDayDialogModes.add,
        defaultResult: []
    });
    const [deleteConfirmDialog, setDeleteConfirmDialog] = useState({
        open: false,
        title: 'Uwaga, czy na pewno chcesz usunąć produkt dnia',
        productOfTheDayId: null
    });
    const isGranted = useIsGranted();

    const canAdd = isGranted.canAddProductsOfTheDay.execute();
    const canEditAndDelete = canAdd;

    const getProductsOfTheDay = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/products-of-the-day`);

            const currentProductsOfTheDayFromResponse = [];
            const scheduledProductsOfTheDayFromResponse = [];

            response.data.forEach(element => {
                if (element.active) {
                    currentProductsOfTheDayFromResponse.push(element);
                } else {
                    scheduledProductsOfTheDayFromResponse.push(element);
                }
            });
            setCurrentProductsOfTheDay(currentProductsOfTheDayFromResponse);
            setScheduledProductsOfTheDay(scheduledProductsOfTheDayFromResponse);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getProductsOfTheDay();
    }, []);

    const handleEditProductOfTheDayBtn = (row) => {
        const defaultResult = [
            {
                productOfTheDayId: row.id,
                id: row.id,
                code: row.product.code,
                brand: row.product.brand,
                color: row.product.color,
                type: row.product.type,
                primary_price: row.product.primary_price,
                promotional_price: row.product.promotional_price,
                productOfTheDayPrice: row.product_of_the_day_promotional_price,
                dateFrom: row.date_from,
                dateTo: row.date_to,
            }
        ];
        setDialogConfig({...dialogConfig, visible: true, mode: productOfTheDayDialogModes.edit, defaultResult})
    }

    const deleteProductOfTheDay = async (productOfTheDayId) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/products-of-the-day/${productOfTheDayId}`);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
        getProductsOfTheDay();
    }

    const handleDeleteProductOfTheDayBtn = (row) => {
        setDeleteConfirmDialog({...deleteConfirmDialog, open: true, productOfTheDayId: row.id});
    }

    const handleDeleteConfirmDialogCancel = () => {
        setDeleteConfirmDialog({...deleteConfirmDialog, open: false, productOfTheDayId: null});
    };

    const handleDeleteConfirmDialogOk = () => {
        const id = deleteConfirmDialog.productOfTheDayId;
        setDeleteConfirmDialog({...deleteConfirmDialog, open: false, productOfTheDayId: null});
        deleteProductOfTheDay(id);
    };

    const getHeaders = () => {
        const headers = [
            text.CODE,
            text.BRAND,
            text.COLOR,
            text.MODEL,
            text.NORMAL_PRICE,
            text.PROMOTIONAL_PRICE,
            text.PRODUCT_OF_THE_DAY_PROMOTIONAL_PRICE,
            text.DATE_FROM,
            text.DATE_TO
        ];
        if (canEditAndDelete) {
            headers.push(text.ACTION);
        }

        return headers;
    }

    const rowRender = (row: ProductOfTheDayInterface) => {
        return (
            <TableRow hover key={row.id}>
                <TableCell align="center">{row.product.code}</TableCell>
                <TableCell align="center">{row.product.brand}</TableCell>
                <TableCell align="center">{row.product.color}</TableCell>
                <TableCell align="center">{row.product.type}</TableCell>
                <TableCell align="center">
                    {formatNumber(row.product.primary_price)}
                    {currencySymbol.PLN}
                </TableCell>
                <TableCell align="center">
                    {formatNumber(row.product.promotional_price)}
                    {currencySymbol.PLN}
                </TableCell>
                <TableCell align="center">
                    {formatNumber(row.product_of_the_day_promotional_price)}
                    {currencySymbol.PLN}
                </TableCell>
                <TableCell align="center">{formatDate(row.date_from)}</TableCell>
                <TableCell align="center">{formatDate(row.date_to)}</TableCell>
                {canEditAndDelete && (
                    <TableCell
                        align="center"
                    >
                        <ButtonGroup
                            style={{ margin: '0px 5px' }}
                            orientation="vertical"
                            color="primary"
                            aria-label="vertical outlined primary button group"
                            variant="outlined"
                            size="small"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => handleEditProductOfTheDayBtn(row)}
                            >
                                Edytuj
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                onClick={() => handleDeleteProductOfTheDayBtn(row)}
                            >
                                Usuń
                            </Button>
                        </ButtonGroup>
                    </TableCell>
                )}
            </TableRow>
        )
    }

    return (
        <>
            {canAdd && (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => setDialogConfig({...dialogConfig, visible: true, mode: productOfTheDayDialogModes.add, defaultResult: []})}
                            className={classes.addProductOfTheDay}
                        >
                            Dodaj produkt dnia
                        </Button>
                    </Grid>
                </Grid>
            )}
            <ViewContentCard title={text.CURRENT_PRODUCTS_OF_THE_DAY}>
                <SaleTableWithPagination
                    headers={getHeaders()}
                    rowsData={currentProductsOfTheDay}
                    rowRender={rowRender}
                /> 
            </ViewContentCard>
            <ViewContentCard title={text.SCHEDULED_PRODUCTS_OF_THE_DAY}> 
                <SaleTableWithPagination
                    headers={getHeaders()}
                    rowsData={scheduledProductsOfTheDay}
                    rowRender={rowRender}
                />
            </ViewContentCard>
            <ProductOfTheDayContext.Provider
                value={{
                    dialogConfig,
                    setDialogConfig,
                    refreshData: getProductsOfTheDay
                }}
            >
                <ProductOfTheDayDialog />
            </ProductOfTheDayContext.Provider>
            <ConfirmDialog
                open={deleteConfirmDialog.open}
                title={deleteConfirmDialog.title}
                handleCancel={handleDeleteConfirmDialogCancel}
                handleOk={handleDeleteConfirmDialogOk}
                cancelLabel="Nie"
                okLabel="Tak"
            />
        </>
    )};

export default ProductOfTheDay;
