import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { Attendance, Pagination } from 'helpers/interface';
import { useQuery, UseQueryOptions } from 'react-query';

export const key = 'attendances';
const path = '/attendances';

type Params = {
    order?: string;
    limit?: number;
    offset?: number;
    dateTo?: string;
    dateFrom?: string;
};


const getAttendances = async (params: Params) => {
    const { data } = await sendAuthAjax('get', path, undefined, params);
    return data;
};

export default function useAttendancesQuery(
    params: Params = {},
    options: UseQueryOptions<Pagination<Attendance[]>, AxiosError> = {}
) {
    return useQuery<Pagination<Attendance[]>, AxiosError>(
        [key, params],
        () => getAttendances(params),
        options
    );
}
