// @flow
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { selectUser } from 'context/features/user/userSlice';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useMenuList from 'hooks/useMenuList';

const useStyles = makeStyles((theme) => ({
    list: {
        paddingBottom: theme.spacing(4),
    },
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 10,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
}));

const Navigator = (props: any) => {
    const classes = useStyles();
    const user = useSelector((state) => selectUser(state));
    const menuList = useMenuList();

    return (
        <Drawer variant="permanent" {...props}>
            <List disablePadding className={classes.list}>
                <ListItem
                    className={clsx(
                        classes.firebase,
                        classes.item,
                        classes.itemCategory
                    )}
                >
                    Menu
                </ListItem>
                {menuList.map(
                    ({ id, path, name, sideMenuVisible, shopRequired}) => {
                        return (
                            sideMenuVisible && (
                                <ListItem
                                    onClick={() => props.onClose()}
                                    key={id}
                                    button
                                    to={path}
                                    className={clsx(
                                        classes.item,
                                        classes.menuItem
                                    )}
                                    component={NavLink}
                                    activeClassName={classes.itemActiveItem}
                                    disabled={
                                        shopRequired && user.shopId === null
                                    }
                                >
                                    <ListItemText
                                        classes={{
                                            primary: classes.itemPrimary,
                                        }}
                                    >
                                        {name}
                                    </ListItemText>
                                </ListItem>
                            )
                        );
                    }
                )}
            </List>
        </Drawer>
    );
};

export default Navigator;
