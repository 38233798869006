import React, { useState, useEffect, useRef } from 'react';
import {
    TableRow,
    TableCell,
    IconButton,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Container,
    makeStyles,
    Paper,
    Chip,
    Input,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SaleTable from 'components/SaleTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch } from 'react-redux';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pl';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import { daysOfWeek } from 'constants/global';
import { sendAuthAjax } from 'helpers/authorization';
import red from '@material-ui/core/colors/red';
import { filter, map, range } from 'lodash';

const useStyles = makeStyles((theme) => ({
    addCoupon: {
        margin: theme.spacing(2, 0),
    },
    formControl: {
        margin: theme.spacing(2, 0),
    },
    container: {
        margin: `${theme.spacing(2)}px auto`,
    },
    expired: {
        backgroundColor: red[50],
    },
    contentCell: {
        wordBreak: 'break-all',
        minWidth: '200px',
    },
    chip: {
        margin: '1px',
    },
}));

const Coupons = () => {
    const classes = useStyles();
    const [coupons, setCoupons] = useState([]);
    const dispatch = useDispatch();
    const [newCouponX, setNewCouponX] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const formRef = {
        code: useRef(null),
        value: useRef(null),
        minPurchaseValue: useRef(null),
        model: useRef(null),
    };

    const clearForm = () => {
        formRef.code.current.value = null;
        formRef.value.current.value = null;
        formRef.minPurchaseValue.current.value = null;
        formRef.model.current.value = null;
        setSelectedDays(range(1,8));
        setDateFrom(moment().startOf('day'));
        setDateTo(moment().add(7, 'days').endOf('day'));
    };

    const handleDaysChange = (event) => {
        setSelectedDays(event.target.value);
    };

    const handleClickRemoveCoupon = async (couponId) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', `/coupons/${couponId}`);
            dispatch(
                addAlert({
                    content: 'Poprawnie usunięto kupon',
                    type: 'success',
                })
            );

            setCoupons((prev) =>
                filter(prev, (coupon) => coupon.id !== couponId)
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickAddCoupon = async () => {
        if (!formRef.code.current || formRef.code.current.value === '') {
            dispatch(
                addAlert({
                    content: 'Pole "Kod" jest wymagane',
                    type: 'error',
                })
            );
            return;
        }
        if (!formRef.value.current || formRef.value.current.value === '') {
            dispatch(
                addAlert({
                    content: 'Pole "Wartość" jest wymagane',
                    type: 'error',
                })
            );
            return;
        }
        if (!dateFrom) {
            dispatch(
                addAlert({
                    content: 'Pole "Data od" jest wymagane',
                    type: 'error',
                })
            );
            return;
        }
        if (!dateTo) {
            dispatch(
                addAlert({
                    content: 'Pole "Data do" jest wymagane',
                    type: 'error',
                })
            );
            return;
        }
        if (moment(dateTo).isSameOrBefore(moment(dateFrom))) {
            dispatch(
                addAlert({
                    content: 'Data "do" nie może być mniejsza od daty "od"',
                    type: 'error',
                })
            );
            return;
        }
        if (!selectedDays || selectedDays === '') {
            dispatch(
                addAlert({
                    content: 'Wybierz, w które dni tygodnia kupon będzie aktywny',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', '/coupons/add', {
                code: formRef.code.current.value,
                value: formRef.value.current.value,
                minPurchaseValue: formRef.minPurchaseValue.current.value,
                model: formRef.model.current.value,
                weekDays: selectedDays,
                dateFrom: moment(dateFrom).format('YYYY-MM-DD HH:mm'),
                dateTo: moment(dateTo).format('YYYY-MM-DD HH:mm'),
            });

            dispatch(
                addAlert({ content: 'Poprawnie dodano kupon', type: 'success' })
            );

            setNewCouponX(moment());
            clearForm();
        } catch (error) {
            const errorText =
                error.response?.data?.message ||
                error.response?.statusText ||
                'Napotkano nieznany błąd';
            dispatch(addAlert({ content: errorText, type: 'error' }));
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    const handleSubmitEnter = (e) => {
        if (e.key === 'Enter') {
            handleClickAddCoupon();
        }
    };

    const getCoupons = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', '/coupons');

            setCoupons(response.data);
        } catch (error) {
            const errorText =
                error.response.data.message || error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getCoupons();
    }, [newCouponX]);

    useEffect(() => {
        clearForm();
        formRef.code.current.focus();
    }, []);

    return (
        <ViewContentCard title={text.ADDING_COUPONS}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Container
                    maxWidth={false}
                    className={classes.container}
                    component={Paper}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                className={classes.formControl}
                                inputRef={formRef.code}
                                label="Kod"
                                onKeyDown={handleSubmitEnter}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                className={classes.formControl}
                                inputRef={formRef.value}
                                type="number"
                                label="Wartość"
                                onKeyDown={handleSubmitEnter}
                                inputProps={{ min: 5, max: 100, step: 5 }}
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (value > 100) {
                                        e.target.value = 100;
                                    } else if (value < 0) {
                                        e.target.value = 0;
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.formControl}
                                inputRef={formRef.minPurchaseValue}
                                type="number"
                                min="1"
                                label="Min. wartość zamówienia"
                                onKeyDown={handleSubmitEnter}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                className={classes.formControl}
                                inputRef={formRef.model}
                                label="Model"
                                onKeyDown={handleSubmitEnter}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDateTimePicker
                                fullWidth
                                required
                                className={classes.formControl}
                                variant="dialog"
                                format="YYYY-MM-DD HH:mm"
                                value={dateFrom}
                                onChange={(date) => setDateFrom(date)}
                                label="Ważny od"
                                minDate={moment()}
                                ampm={false}
                                clearable
                                clearLabel="Wyczyść"
                                cancelLabel="Anuluj"
                                onKeyDown={handleSubmitEnter}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDateTimePicker
                                fullWidth
                                required
                                className={classes.formControl}
                                variant="dialog"
                                format="YYYY-MM-DD HH:mm"
                                value={dateTo}
                                onChange={(date) => setDateTo(date)}
                                label="Ważny do"
                                minDate={moment()}
                                ampm={false}
                                clearable
                                clearLabel="Wyczyść"
                                cancelLabel="Anuluj"
                                onKeyDown={handleSubmitEnter}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="days-of-week-label">
                                    W jakie dni aktywny
                                </InputLabel>
                                <Select
                                    labelId="days-of-week-label"
                                    multiple
                                    value={selectedDays}
                                    onChange={handleDaysChange}
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {map(selected, (value) => {
                                                const day = daysOfWeek.find(
                                                    (day) => day.id === value
                                                );
                                                return (
                                                    <Chip
                                                        key={value}
                                                        label={day.name}
                                                        className={classes.chip}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                >
                                    {map(daysOfWeek, (day) => (
                                        <MenuItem key={day.id} value={day.id}>
                                            {day.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                fullWidth
                                className={classes.addCoupon}
                                onClick={handleClickAddCoupon}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {text.ADD_COUPON}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </MuiPickersUtilsProvider>
            {coupons.length > 0 && (
                <SaleTable
                    headers={[
                        text.REMOVE,
                        'Kod',
                        'Wartość',
                        'Min. wartość zakupu',
                        'Model',
                        'Ważny w dni',
                        'Ważny od',
                        'Ważny do',
                    ]}
                    rowRender={(row) => (
                        <TableRow hover key={row.id}>
                            <TableCell align="center">
                                <IconButton
                                    onClick={() =>
                                        handleClickRemoveCoupon(row.id)
                                    }
                                >
                                    <DeleteOutlineIcon color="error" />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{`${Math.trunc(row.value)}%`}</TableCell>
                            <TableCell align="center">
                                {row.minPurchaseValue
                                    ? Intl.NumberFormat('pl-PL', {
                                          style: 'currency',
                                          currency: 'PLN',
                                      }).format(row.minPurchaseValue)
                                    : 0}
                            </TableCell>
                            <TableCell align="center">
                                {row.model ?? '*'}
                            </TableCell>
                            <TableCell align="center">
                                {map(row.activeDays, (key) => (
                                    <Chip
                                        key={key}
                                        label={key}
                                        className={classes.chip}
                                    />
                                ))}
                            </TableCell>
                            <TableCell align="center">
                                {moment(row.dateFrom).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </TableCell>
                            <TableCell align="center">
                                {moment(row.dateFrom).format(
                                    'YYYY-MM-DD HH:mm'
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    rowsData={coupons}
                />
            )}
        </ViewContentCard>
    );
};

export default Coupons;
