import {
    Button,
    Dialog,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { addAlert } from 'context/features/global/globalSlice';
import { Attendance, Sort } from 'helpers/interface';
import useAttendancesQuery, { key } from 'hooks/useAttendancesQuery';
import useIsGranted from 'hooks/useIsGranted';
import useRemoveAttendanceMutation from 'hooks/useRemoveAttendanceMutation';
import moment, { Moment } from 'moment';
import React, { memo, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import AttendanceDialogContent from './AttendanceDialogContent';
import AttendanceTable from './AttendanceTable';

const useStyles = makeStyles(() => ({
    addToAttendanceButton: {
        marginBottom: '10px',
    },
    dateFilterContainer: {
        marginBottom: '10px',
    },
}));

const pageSize = 10;

function AttendanceList() {
    const queryClient = useQueryClient();
    const isGranted = useIsGranted();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [attendance, setAttendance] = useState<Attendance | null>(null);
    const [sort, setSort] = useState<Sort | null>({
        key: 'date',
        direction: 'desc',
    });
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [filters, setFilters] = useState<{
        dateTo: null | Moment;
        dateFrom: null | Moment;
    }>({ dateTo: null, dateFrom: null });

    const { data, isLoading, isFetching } = useAttendancesQuery(
        {
            limit: pageSize,
            offset: page * pageSize,
            dateTo: filters?.dateTo
                ? filters?.dateTo?.format('YYYY-MM-DD')
                : undefined,
            dateFrom: filters?.dateFrom
                ? filters?.dateFrom?.format('YYYY-MM-DD')
                : undefined,
            order: sort ? `${sort.key} ${sort.direction}` : undefined,
        },
        { keepPreviousData: true }
    );

    const { mutate: removeAttendance } = useRemoveAttendanceMutation();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleShow = useCallback(() => {
        setOpen(true);
    }, []);

    const handleRemove = useCallback((id: number) => {
        removeAttendance(id, {
            onSuccess: () => {
                dispatch(
                    addAlert({
                        content: 'Poprawnie usunięto',
                        type: 'success',
                    })
                );

                queryClient.invalidateQueries([key]);
            },
            onError: (err) => {
                const errorText = err?.response?.data?.message
                    ? err?.response?.data?.message
                    : err?.response?.statusText;

                dispatch(addAlert({ content: errorText, type: 'error' }));
            },
        });
    }, []);

    const handleEdit = useCallback((row: Attendance) => {
        setAttendance(row);
        setOpen(true);
    }, []);

    const accessToAdd = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_DIRECTOR_CODE,
        text.ROLE_EMPLOYEE_CODE,
    ]);

    const loading = isLoading || isFetching;

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <AttendanceDialogContent
                    onAdd={handleClose}
                    attendance={attendance}
                />
            </Dialog>
            {accessToAdd && (
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            className={classes.addToAttendanceButton}
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleShow}
                        >
                            Dodaj do listy obecności
                        </Button>
                    </Grid>
                </Grid>
            )}
            <ViewContentCard title={text.ATTENDANCE_LIST}>
                <Grid
                    container
                    spacing={1}
                    className={classes.dateFilterContainer}
                >
                    <Grid item xs={12} md={2}>
                        <KeyboardDatePicker
                            InputProps={
                                filters.dateFrom
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <IconButton
                                                      size="small"
                                                      edge="end"
                                                      onClick={() => {
                                                          setPage(0);
                                                          setFilters({
                                                              ...filters,
                                                              dateFrom: null,
                                                          });
                                                      }}
                                                  >
                                                      <ClearIcon fontSize="small" />
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }
                                    : undefined
                            }
                            disabled={loading}
                            shouldDisableDate={(date) => {
                                const d1 = moment(date).startOf('day');

                                const d2 = filters.dateTo
                                    ? filters.dateTo.startOf('day')
                                    : null;

                                if (!d2 || d1.isSame(d2)) {
                                    return false;
                                }

                                return moment(date).isAfter(filters.dateTo);
                            }}
                            fullWidth
                            label="Data od"
                            format="YYYY-MM-DD"
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            value={filters.dateFrom}
                            onChange={(e) => {
                                setPage(0);
                                setFilters({ ...filters, dateFrom: e });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <KeyboardDatePicker
                            shouldDisableDate={(date) => {
                                const d1 = moment(date).startOf('day');

                                const d2 = filters.dateFrom
                                    ? filters.dateFrom.startOf('day')
                                    : null;

                                if (!d2 || d1.isSame(d2)) {
                                    return false;
                                }

                                return moment(date).isBefore(filters.dateFrom);
                            }}
                            InputProps={
                                filters.dateTo
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <IconButton
                                                      size="small"
                                                      edge="end"
                                                      onClick={() => {
                                                          setPage(0);
                                                          setFilters({
                                                              ...filters,
                                                              dateTo: null,
                                                          });
                                                      }}
                                                  >
                                                      <ClearIcon fontSize="small" />
                                                  </IconButton>
                                              </InputAdornment>
                                          ),
                                      }
                                    : undefined
                            }
                            disabled={loading}
                            fullWidth
                            label="Data do"
                            format="YYYY-MM-DD"
                            size="small"
                            variant="inline"
                            inputVariant="outlined"
                            value={filters.dateTo}
                            onChange={(e) => {
                                setPage(0);
                                setFilters({ ...filters, dateTo: e });
                            }}
                        />
                    </Grid>
                </Grid>
                <AttendanceTable
                    onEdit={handleEdit}
                    onRemove={handleRemove}
                    pageSize={pageSize}
                    loading={loading}
                    data={data}
                    setPage={setPage}
                    page={page}
                    sort={sort}
                    setSort={setSort}
                />
            </ViewContentCard>
        </>
    );
}

export default memo(AttendanceList);
