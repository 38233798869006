// @flow
import React, { useEffect, useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReleaseTableRow from 'components/ReleaseTableRow';
import { selectUser } from 'context/features/user/userSlice';
import { validateNumberValue } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { clearSearchParams } from 'context/features/sale/saleSlice';

const ReleaseShoes = () => {
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();
    const { releaseId } = useParams();
    const user = useSelector((state) => selectUser(state));
    const [discounts, setDiscounts] = useState([]);
    const isGranted = useIsGranted();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_DIRECTOR_CODE,
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);

    const getDiscounts = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/employee-exchange-discounts`
            );

            setDiscounts(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClick = async (productId: number, data: any) => {
        const { sizeTo, info, discount } = data;
        if (!validateNumberValue(sizeTo)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }
        if (!info) {
            dispatch(
                addAlert({
                    content: 'Wypełnij pole z informacją',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/exchange/product-employee-release`, {
                sizeTo,
                info,
                productId,
                shopId: user.shopId,
                discount
            });
            dispatch(clearSearchParams());
            setResult([]);
            dispatch(addAlert({ content: 'Wydano obuwie', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const headers = releaseId
        ? [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              text.SIZE,
              '',
          ]
        : [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              ...(hasPermissions ? [text.EMPLOYEE_EXCHANGE_DISCOUNT] : []),
              text.INFO,
              text.SIZE,
              '',
          ];

    useEffect(() => {
        getDiscounts();
    }, []);

    return (
        <ViewContentCard title={text.SHOE_RELEASE}>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={headers}
                    rowRender={(row) => (
                        <ReleaseTableRow
                            key={row.id}
                            row={row}
                            handleClick={handleClick}
                            discounts={discounts}
                            hasPermissions={hasPermissions}
                        />
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default ReleaseShoes;
