// @flow
import React, { useState, useRef } from 'react';
import { TableRow, TableCell, makeStyles, ButtonGroup, Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { filterShopsWithoutEcommerce, formatPrice } from 'helpers/global';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, addAlert, selectShops } from 'context/features/global/globalSlice';
import { sendAuthFetch } from 'helpers/authorization';
import { selectUser } from 'context/features/user/userSlice';
import { Shop, Shoe } from 'helpers/interface';

const useStyles = makeStyles(() => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    container: {
        margin: '10px 0px',
        width: '100%',
    }
}));

const Labels = () => {
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const user = useSelector((state) => selectUser(state));
    const dispatch = useDispatch();
    const ref = useRef(null);
    const shops = useSelector((state) => selectShops(state));
    const warehouseId = shops.filter((shop) => shop.name === 'Magazyn')[0].id;
    const [selectedShop, setSelectedShop] = useState( !user.shopId ? warehouseId : user.shopId );

    const getLabels = async (id: number) => {
        dispatch(setIsLoading(true));

        try {
            const shopId = user.shopId ?? selectedShop;
            const response = await sendAuthFetch(`/products/labels/${id}/${shopId}/pdf`);
            const blobby = await response.blob();
            const objectUrl = window.URL.createObjectURL(blobby);

            ref.current.href = objectUrl;
            ref.current.download = 'etykiety.pdf';
            ref.current.click();

            window.URL.revokeObjectURL(objectUrl);
        } catch (err) {
            const error: Error = err;
            const errorText = error.response ? error.response.data.message : 'Wystąpił błąd podczas generowania etykiet';
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <ViewContentCard title={text.LABELS}>
            {(user.shopId === null || user.shopId === '') &&
            <Grid container alignItems="center" spacing={1} className={classes.container}>
                <Grid item xs={12} md={4} lg={2}>
                    <FormControl
                        fullWidth
                        margin="dense"
                        variant="outlined"
                    >
                        <InputLabel shrink id="shop-select-label">
                            Sklep
                        </InputLabel>
                        <Select
                            labelId="shop-select-label"
                            value={selectedShop}
                            label="Sklep"
                            onChange={(e) =>
                                setSelectedShop(e.target.value)
                            }
                        >
                            {filterShopsWithoutEcommerce(shops, (shop: Shop) => (
                                <MenuItem key={shop.id} value={shop.id}>
                                    {shop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            }
            <SearchBar setResult={setResult} shopIdToSearch={selectedShop} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.NORMAL_PRICE,
                        text.PROMOTIONAL_PRICE,
                        text.ACTION,
                    ]}
                    rowRender={(row: Shoe) => (
                        <TableRow
                            className={classes.row}
                            hover
                            key={row.id}
                        >
                            <TableCell align="center">{row.code}</TableCell>
                            <TableCell align="center">{row.brand}</TableCell>
                            <TableCell align="center">{row.color}</TableCell>
                            <TableCell align="center">{row.type}</TableCell>
                            <TableCell align="center">{formatPrice(row.primary_price)}</TableCell>
                            <TableCell align="center">{formatPrice(row.promotional_price)}</TableCell>
                            <TableCell align="center">
                                <ButtonGroup
                                    style={{ margin: '0px 5px' }}
                                    orientation="vertical"
                                    color="primary"
                                    aria-label="vertical outlined primary button group"
                                    variant="outlined"
                                    size="small"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => getLabels(row.id)}
                                    >
                                        Etykiety
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                            
                        </TableRow>
                    )}
                    rowsData={result}
                />
            )}
            <a style={{ display: 'none' }} ref={ref} />
        </ViewContentCard>
    );
};

export default Labels;
