// @flow
import React, { useState, useEffect, useRef } from 'react';
import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
} from '@material-ui/core';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import { HuePicker } from 'react-color';
import FormAddSizes from './FormAddSizes';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingBottom: theme.spacing(2),
    },
    dialogTitle: {
        paddingBottom: 0,
    },
}));

const Picker = React.forwardRef((props, ref) => {
    const [color, setColor] = useState('');

    useEffect(() => {
        setColor(props.color);
    }, [props.color]);

    return (
        <HuePicker ref={ref} color={color} onChange={(e) => setColor(e.hex)} />
    );
});

const DictionaryDialog = ({
    open,
    handleClose,
    id = null,
    category,
}: {
    open: boolean,
    handleClose: () => void,
    id: number | null,
    category: string,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [value, setValue] = useState('');
    const [sizes, setSizes] = useState([]);
    const [color, setColor] = useState('#009be5');
    const ref = useRef(null);

    const resetDialog = () => {
        setValue('');
        setSizes([]);
        setColor('');
    };

    const handleCloseDialog = () => {
        resetDialog();
        handleClose();
    };

    const getAdditionalData = () => {
        let additionalData;
        switch (category) {
            case 'sizeStandards':
                additionalData = JSON.stringify({
                    sizes,
                    color: ref.current.state.hex,
                });
                break;
            case 'graphicTypes':
                additionalData = JSON.stringify({
                    color: ref.current.state.hex,
                });
                break;
            default:
                additionalData = null;
                break;
        }

        return additionalData;
    };

    const handleClickSave = async () => {
        const payload = {
            value,
            additional_data: getAdditionalData(),
            code_name: `${category}_${value}`,
            category,
        };

        if (!value) {
            dispatch(
                addAlert({
                    content: 'Wartość nie może być pusta',
                    type: 'error',
                })
            );

            return;
        }

        if (category === 'sizeStandards' && sizes.length <= 0) {
            dispatch(addAlert({ content: 'Dodaj rozmiary', type: 'error' }));

            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax(
                `${id !== null ? 'put' : 'post'}`,
                `${id !== null ? `/dictionaries/${id}` : '/dictionaries'}`,
                payload
            );
            dispatch(
                addAlert({ content: 'Poprawnie zapisano', type: 'success' })
            );
            handleCloseDialog();
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const setResult = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/dictionaries/${id}`);

            setValue(response.data.value);
            setSizes(
                response.data.additional_data !== null &&
                    response.data.additional_data.sizes !== undefined
                    ? response.data.additional_data.sizes
                    : []
            );
            setColor(
                response.data.additional_data !== null &&
                    response.data.additional_data.color !== undefined
                    ? response.data.additional_data.color
                    : ''
            );
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (id !== null) {
            setResult();
        }
    }, [id]);

    return (
        <Dialog
            className={classes.dialogTable}
            maxWidth={false}
            open={open}
            onClose={handleCloseDialog}
            style={{ maxHeight: '90vh' }}
        >
            <DialogTitle className={classes.dialogTitle}>
                {id !== null
                    ? 'Edycja wartości ze słownika'
                    : 'Dodawanie wartości do słownika'}
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Wartość"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                    </Grid>
                    {category === 'sizeStandards' && (
                        <Grid item xs={12}>
                            <FormAddSizes sizes={sizes} setSizes={setSizes} />
                        </Grid>
                    )}
                    {(category === 'graphicTypes' ||
                        category === 'sizeStandards') && (
                        <Grid item xs={12}>
                            <Picker ref={ref} color={color} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                    Anuluj
                </Button>
                <Button onClick={handleClickSave} color="primary">
                    Zapisz
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo<Props>(DictionaryDialog);
