// @flow
import React, {useEffect, useState} from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { createShoeBlockHeader, scrollTop } from 'helpers/global';
import FindShoeBlock from 'components/FindShoeBlock';
import { AxiosError } from "axios";
import type { Inventory } from "../helpers/interface";

const useStyles = makeStyles(() => ({
    row: {
        '&:hover': { cursor: 'pointer' },
    },
    marginB: {
        marginBottom: '4rem',
    }
}));

const Warehouse = () => {
    const classes = useStyles();
    const [products, setProducts] = useState([]);
    const [shoeFoundId, setShoeFound] = useState(null);
    const [shoeTitle, setShoeTitle] = useState('');
    const dispatch = useDispatch();

    const handleRowClick = (rowId: number, _shoeTitle: string) => {
        setShoeFound(rowId !== shoeFoundId ? rowId : null);
        setShoeTitle(_shoeTitle);
        setTimeout(() => {
            scrollTop();
        }, 100);
    };

    const getMainWarehouseList = async() => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/warehouse`);
            setProducts(response.data);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    }

    useEffect(() => {
        setShoeFound(null);
        getMainWarehouseList();
    }, []);

    return (
        <ViewContentCard title={text.WAREHOUSE}>
            {shoeFoundId !== null &&
                <div className={classes.marginB}>
                    <FindShoeBlock shoeId={shoeFoundId} title={shoeTitle} />
                </div>
            }
            {products && products.length > 0 && (
                <SaleTableWithPagination
                    headers={
                        [
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.TYPE,
                            text.QUANTITY,
                        ]
                    }
                    rowsPerPageNum={50}
                    rowRender={(row: Inventory) => (
                        <TableRow
                            className={classes.row}
                            hover
                            key={row.inventoryId}
                            onClick={() => handleRowClick(row.productId, createShoeBlockHeader(row))}
                            selected={shoeFoundId === row.productId}
                        >
                            <TableCell align='center'>{row.code}</TableCell>
                            <TableCell align='center'>{row.brand}</TableCell>
                            <TableCell align='center'>{row.color}</TableCell>
                            <TableCell align='center'>{row.type}</TableCell>
                            <TableCell align='center'>{row.quantity}</TableCell>
                        </TableRow>
                    )}
                    rowsData={products}
                />
            )}
        </ViewContentCard>
    );
};

export default Warehouse;