// @flow
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pl';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import Alert from '@material-ui/lab/Alert';
import { sendAuthAjax } from 'helpers/authorization';

const useStyles = makeStyles((theme) => ({
    alert: {
        margin: '5px 0px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            '& .MuiAlert-icon': {
                display: 'none',
            },
            flexWrap: 'wrap',
        },
    },
    message: {
        wordBreak: 'break-all',
    },
}));

const AlertsHistory = () => {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const dispatch = useDispatch();

    const getMessages = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax('get', '/users/messages/seen');

            setMessages(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const sort = (x, y) => {
        return (
            moment(y.created_at).format('X') - moment(x.created_at).format('X')
        );
    };

    useEffect(() => {
        getMessages();
    }, []);

    return (
        <ViewContentCard title={text.HISTORY_MESSAGES}>
            {messages.length > 0
                ? messages.sort(sort).map((message) => (
                      <Alert
                          color="info"
                          severity="info"
                          className={classes.alert}
                          key={message.id}
                      >
                          <Typography
                              className={classes.message}
                              variant="h6"
                              component="span"
                          >
                              {message.content}
                          </Typography>
                          <Typography variant="body2" component="span">
                              {` - od ${message.author_name}`}
                          </Typography>
                      </Alert>
                  ))
                : 'Brak komunikatów'}
        </ViewContentCard>
    );
};

export default AlertsHistory;
