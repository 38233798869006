import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Pie } from 'react-chartjs-2';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    useTheme,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import SalesDetailFilters from '../SalesDetailFilters';
import green from '@material-ui/core/colors/green';
import pink from '@material-ui/core/colors/pink';
import orange from '@material-ui/core/colors/orange';
import purple from '@material-ui/core/colors/purple';
import { map, keys } from 'lodash';

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        display: 'block',
    },
    boxConainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
        justifyContent: 'space-between',
    },
    pieContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    chartBox: {
        flex: 1,
    },
}));

const SalesEfficiencyDetail = () => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState('PLN');
    const [dataCurrent, setDataCurrent] = useState(null);
    const [dataPrevious, setDataPrevious] = useState(null);
    const [currentTableData, setCurrentTableData] = useState(null);
    const [previousTableData, setPreviousTableData] = useState(null);
    const [previousTitle, setPreviousTitle] = useState(null);
    const [currentTitle, setCurrentTitle] = useState(null);
    const [filters, setFilters] = useState({
        rangeCheckbox: false,
        dateFrom: moment().startOf('month'),
        dateTo: moment(),
        period: 'lastMonth',
        shopId: '',
        currency: '',
        monthOffset: '',
    });
    const colors = [pink[100], green[200], purple[400], orange[400]];

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 0,
                bottom: 10,
            },
        },
        radius: window.innerWidth < 768 ? 70 : 120,
    };

    const currentDataPieOptions = {
        ...options,
        plugins: {
            title: {
                display: true,
                text: 'Wyświetlany zakres: ' + currentTitle,
            },
        },
    };

    const previousDataPieOptions = {
        ...options,
        plugins: {
            title: {
                display: true,
                text: 'Wyświetlany zakres: ' + previousTitle,
            },
        },
    };

    const preparePieData = (periodData) => {
        const labels = keys(periodData);
        const values = map(labels, (key) => periodData[key].quantity);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Ilość',
                    data: values,
                    backgroundColor: colors,
                    hoverOffset: 4,
                },
            ],
        };
    };

    const prepareTableDataPrev = (responseData) => {
        let prepareData = map(keys(responseData), (key, index) => ({
            group: key,
            quantity: responseData[key].quantity,
            percent: responseData[key].percent ?? null,
            amount: responseData[key].amount,
            color: colors[index],
        }));

        return prepareData;
    };

    const prepareTableDataCurrent = (responseData, responseDataPrev) => {
        let prepareData = map(keys(responseData), (key, index) => ({
            group: key,
            quantity: responseData[key].quantity,
            quantityPrev: responseDataPrev[key].quantity,
            percent: responseData[key].percentToPreviousPeriod ?? null,
            amount: responseData[key].amount,
            color: colors[index],
        }));

        return prepareData;
    };

    useEffect(() => {
        const getSalesData = async () => {
            if (filters.rangeCheckbox && filters.dateTo < filters.dateFrom) {
                dispatch(
                    addAlert({
                        content:
                            'Data "Do" nie może być wcześniejsza niż data "Od"',
                        type: 'error',
                    })
                );
                return;
            }

            dispatch(setIsLoading(true));
            const searchParams = new URLSearchParams();
            searchParams.append(
                'dateFrom',
                filters.dateFrom.format('YYYY-MM-DD')
            );
            searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));
            if (filters.shopId) {
                searchParams.append('shopId', filters.shopId);
            }
            if (filters.currency) {
                searchParams.append('currency', filters.currency);
            }
            if (filters.monthOffset) {
                searchParams.append('monthOffset', filters.monthOffset);
            }

            try {
                const responseData = await sendAuthAjax(
                    'get',
                    '/reports/efficiency',
                    undefined,
                    searchParams
                );

                setDataCurrent(preparePieData(responseData.data.currentPeriod));
                setDataPrevious(
                    preparePieData(responseData.data.previousPeriod)
                );
                setCurrentTableData(
                    prepareTableDataCurrent(
                        responseData.data.currentPeriod,
                        responseData.data.previousPeriod
                    )
                );
                setPreviousTableData(
                    prepareTableDataPrev(responseData.data.previousPeriod)
                );

                setCurrency(responseData.data.currency);
                setPreviousTitle(responseData.data.previousRange);
                setCurrentTitle(responseData.data.currentRange);
            } catch (error) {
                const errorText =
                    error.response?.data?.message || error.response?.statusText;
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }
            dispatch(setIsLoading(false));
        };

        getSalesData();
    }, [filters, dispatch]);

    return (
        <Card className={clsx(classes.root)}>
            <CardHeader
                style={{ flexWrap: 'nowrap' }}
                className={classes.cardHeader}
                action={
                    <SalesDetailFilters
                        filters={filters}
                        setFilters={setFilters}
                        showHourSelect={false}
                        showCurrencySelect={true}
                        showMonthOffsetSelect={true}
                    />
                }
                title="Efektywność sprzedaży"
            />
            <Divider />
            <CardContent className={classes.boxConainer}>
                <Box className={classes.pieContainer}>
                    <Box
                        height={400}
                        position="relative"
                        className={classes.chartBox}
                    >
                        {dataCurrent && (
                            <Pie
                                data={dataCurrent}
                                options={currentDataPieOptions}
                            />
                        )}
                    </Box>
                    <Box mt={2} className={classes.chartBox}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={100}>Grupa</TableCell>
                                        <TableCell>Szt. obecny zakres</TableCell>
                                        <TableCell>
                                            Szt. ubiegły zakres
                                        </TableCell>
                                        <TableCell>
                                            % względem poprzedniego zakresu
                                        </TableCell>
                                        <TableCell>
                                            Kwota za obecny zakres
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {currentTableData &&
                                    currentTableData.length > 0 ? (
                                        map(currentTableData, (row, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell
                                                    style={{
                                                        backgroundColor:
                                                            row.color,
                                                    }}
                                                >
                                                    {row.group}
                                                </TableCell>
                                                <TableCell>
                                                    {row.quantity}
                                                </TableCell>
                                                <TableCell>
                                                    {row.quantityPrev}
                                                </TableCell>
                                                <TableCell>
                                                    {row.percent}%
                                                </TableCell>
                                                <TableCell>
                                                    {Intl.NumberFormat(
                                                        'pl-PL',
                                                        {
                                                            style: 'currency',
                                                            currency: currency,
                                                        }
                                                    ).format(row.amount)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={4}
                                                align="center"
                                            >
                                                Brak danych do wyświetlenia
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
                <Box className={classes.pieContainer}>
                    <Box
                        height={400}
                        position="relative"
                        className={classes.chartBox}
                    >
                        {dataPrevious && (
                            <Pie
                                data={dataPrevious}
                                options={previousDataPieOptions}
                            />
                        )}
                    </Box>
                    <Box mt={2} className={classes.chartBox}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={100}>Grupa</TableCell>
                                        <TableCell>
                                            Szt. ubiegły zakres
                                        </TableCell>
                                        <TableCell>Kwota</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {previousTableData &&
                                    previousTableData.length > 0 ? (
                                        map(previousTableData, (row, index) => (
                                            <TableRow hover key={index}>
                                                <TableCell
                                                    style={{
                                                        backgroundColor:
                                                            row.color,
                                                    }}
                                                >
                                                    {row.group}
                                                </TableCell>
                                                <TableCell>
                                                    {row.quantity}
                                                </TableCell>
                                                <TableCell>
                                                    {Intl.NumberFormat(
                                                        'pl-PL',
                                                        {
                                                            style: 'currency',
                                                            currency: currency,
                                                        }
                                                    ).format(row.amount)}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={4}
                                                align="center"
                                            >
                                                Brak danych do wyświetlenia
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default SalesEfficiencyDetail;
