import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { Attendance, ChecklistCategory, Pagination } from 'helpers/interface';
import { useQuery, UseQueryOptions } from 'react-query';

export const key = 'checklist-categories';
const path = '/checklist-categories';

const getAttendances = async () => {
    const { data } = await sendAuthAjax('get', path);
    return data;
};

export default function useChecklistCategoriesQuery() {
    return useQuery<ChecklistCategory[], AxiosError>(
        key,
        () => getAttendances(),
    );
}
