// @flow
import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    makeStyles,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import {
    setIsLoading,
    addAlert,
    selectShops,
    selectAllRoles,
} from 'context/features/global/globalSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAuthAjax } from 'helpers/authorization';
import * as text from 'constants/strings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { selectUser } from 'context/features/user/userSlice';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 2),
    },
}));

type Props = {
    userId: ?number,
};

const WorkerFormEdit = ({ userId }: Props) => {
    const classes = useStyles();
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        role: '',
        shopId: '',
        userName: '',
        password: '',
        email: '',
    });
    const dispatch = useDispatch();
    const shops = useSelector((state) => selectShops(state));
    const allRoles = useSelector((state) => selectAllRoles(state));
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const user = useSelector((state) => selectUser(state));

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const getUser = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/users/${userId}`);
            const data = {
                ...response.data,
                role: response.data.roles[0],
                password: '',
            };

            delete data.roles;

            setUserData(data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const validateFormData = () => {
        if (userId) {
            const requiredFields = Object.entries(userData).filter(
                ([key]) => key !== 'password'
            );

            return requiredFields.every(
                ([, value]) => value.toString().length > 0
            );
        }
        return Object.entries(userData).every(
            ([, value]) => value.toString().length > 0
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFormData()) {
            dispatch(addAlert({ content: 'Niekompletne dane', type: 'error' }));

            return;
        }

        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax(
                `${userId !== undefined ? 'put' : 'post'}`,
                `${userId !== undefined ? `/users/${userId}` : '/users'}`,
                userData
            );
            dispatch(
                addAlert({
                    content: `Poprawnie ${
                        userId !== undefined ? 'zmodyfikowano' : 'dodano'
                    } użytkownika ${userData.userName}`,
                    type: 'success',
                })
            );
            if (userId && parseInt(userId, 10) === parseInt(user.userId, 10)) {
                history.push(`/logout`);
            } else {
                history.push(`/pracownicy`);
            }
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (userId) {
            getUser();
        }
    }, []);

    return (
        Object.keys(userData).length > 0 && (
            <Container component="main" maxWidth="xs">
                <form onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Login"
                        value={userData.userName}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                userName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        value={userData.email}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                email: e.target.value,
                            })
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Imię"
                        value={userData.firstName}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                firstName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Nazwisko"
                        value={userData.lastName}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                lastName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Hasło"
                        type={showPassword ? 'text' : 'password'}
                        value={userData.password}
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color="inherit"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) =>
                            setUserData({
                                ...userData,
                                password: e.target.value,
                            })
                        }
                    />
                    <FormControl
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel id="shop-select">Sklep</InputLabel>
                        <Select
                            labelId="shop-select"
                            value={userData.shopId || ''}
                            label="Sklep"
                            onChange={(e) =>
                                setUserData({
                                    ...userData,
                                    shopId: e.target.value,
                                })
                            }
                        >
                            {shops.map((shop) => (
                                <MenuItem key={shop.id} value={shop.id}>
                                    {shop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                        margin="normal"
                    >
                        <InputLabel id="role-select">Rola</InputLabel>
                        <Select
                            labelId="role-select"
                            value={userData.role}
                            label="Rola"
                            onChange={(e) =>
                                setUserData({
                                    ...userData,
                                    role: e.target.value,
                                })
                            }
                        >
                            {allRoles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {text[role]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {userId !== undefined ? 'Edytuj' : 'Dodaj'}
                    </Button>
                </form>
            </Container>
        )
    );
};

export default WorkerFormEdit;
