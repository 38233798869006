// @flow
import React, { useState, memo, useEffect } from 'react';
import {
    Button,
    TextField,
    TableRow,
    TableCell,
    Box,
    makeStyles,
} from '@material-ui/core';
import {
    setIsLoading,
    addAlert
} from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { PLN, currencySymbol, productOfTheDayDialogModes } from 'constants/global';
import { isEqual } from 'lodash';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { sendAuthAjax } from 'helpers/authorization';
import moment from 'moment';
import * as text from 'constants/strings';

type Props = {
    rowData: Array<string>,
    refreshData: () => void,
    mode: string
};

const useStyles = makeStyles(() => ({
    priceInput: {
        width: '100px',
        marginRight: '10px',
    },
    selectCalendar: {
        marginTop: '10px',
        marginRight: '5px'
    },
}));

const ProductOfTheDayDialogTableRow = ({
    rowData,
    refreshData,
    mode = productOfTheDayDialogModes.add
}: Props) => {
    const classes = useStyles();
    const [selectedDateFrom, setSelectedDateFrom] = useState(new Date());
    const [selectedDateTo, setSelectedDateTo] = useState(new Date());
    const [productOfTheDayPrice, setProductOfTheDayPrice] = useState();
    const dispatch = useDispatch();

    const addProductOfTheDay = async () => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax(
                'post',
                '/products-of-the-day',
                {
                    productId: rowData.id,
                    productOfTheDayPrice,
                    dateFrom: moment(selectedDateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(selectedDateTo).format('YYYY-MM-DD'),
                },
            );
            dispatch(
                addAlert({ content: 'Poprawnie dodano', type: 'success' })
            );
            refreshData();
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const editProductOfTheDay = async (productOfTheDayId: number) => {
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax(
                'put',
                `/products-of-the-day/${productOfTheDayId}`,
                {
                    productOfTheDayPrice,
                    dateFrom: moment(selectedDateFrom).format('YYYY-MM-DD'),
                    dateTo: moment(selectedDateTo).format('YYYY-MM-DD'),
                },
            );
            dispatch(
                addAlert({ content: 'Poprawnie edytowano', type: 'success' })
            );
            refreshData();
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRow = () => {
        if (mode === productOfTheDayDialogModes.add) {
            addProductOfTheDay();
        }
        if (mode === productOfTheDayDialogModes.edit) {
            editProductOfTheDay(rowData.productOfTheDayId)
        }
    };

    useEffect(() => {
        if (mode === productOfTheDayDialogModes.edit) {
            setProductOfTheDayPrice(rowData.productOfTheDayPrice);
            setSelectedDateFrom(rowData.dateFrom);
            setSelectedDateTo(rowData.dateTo);
        }
      }, [rowData]);

    return (
        <TableRow hover>
            <TableCell align="center">{rowData.code}</TableCell>
            <TableCell align="center">{rowData.brand}</TableCell>
            <TableCell align="center">{rowData.color}</TableCell>
            <TableCell align="center">{rowData.type}</TableCell>
            <TableCell align="center">
                {`${rowData.primary_price} ${currencySymbol[PLN]}`}
            </TableCell>
            <TableCell align="center">
                {`${rowData.promotional_price} ${currencySymbol[PLN]}`}
            </TableCell>
            <TableCell align="center">
                <Box display="flex" width="150" justifyContent="center">
                    <TextField
                        value={productOfTheDayPrice || ''}
                        className={classes.priceInput}
                        placeholder={currencySymbol[PLN]}
                        type="number"
                        onChange={(e) =>
                            setProductOfTheDayPrice(e.target.value)
                        }
                    />
                </Box>
            </TableCell>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <TableCell align="right">
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="YYYY-MM-DD"
                        margin="normal"
                        value={selectedDateFrom}
                        onChange={(event) => setSelectedDateFrom(event)}
                        className={classes.selectCalendar}
                    />
                </TableCell>
                <TableCell align="right">
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        format="YYYY-MM-DD"
                        margin="normal"
                        value={selectedDateTo}
                        onChange={(event) => setSelectedDateTo(event)}
                        className={classes.selectCalendar}
                    />
                </TableCell>
            </MuiPickersUtilsProvider>
            <TableCell align="right">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickRow()}
                >
                    {mode === productOfTheDayDialogModes.add && text.ADD}
                    {mode === productOfTheDayDialogModes.edit && text.EDIT}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo<Props>(
    ProductOfTheDayDialogTableRow,
    (prev, next) => isEqual(prev, next)
);
