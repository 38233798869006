// @flow
import React, { useState, useEffect } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    IconButton,
    Button,
    makeStyles,
    Grid,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SaleTable from 'components/SaleTable';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ColoringShop from 'components/ColoringShop';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import CustomShortageDialog from 'components/CustomShortageDialog';
import SaleTableWithPagination from 'components/SaleTableWithPagination';

const useStyles = makeStyles((theme) => ({
    btn: {
        marginBottom: theme.spacing(1),
    },
}));

const TrendBlock = ({ difference }: { difference: number }) => {
    return (
        <>
            {difference > 0 ? (
                <TrendingUpIcon color="primary" />
            ) : (
                <TrendingDownIcon color="secondary" />
            )}
            <Typography variant="subtitle2">
                {difference > 0 ? `+${difference}` : difference}
            </Typography>
        </>
    );
};

const Shortages = () => {
    const [result, setResult] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [type, setType] = useState('existing');
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const getShortages = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/shortages/${type}`);

            setResult(response.data);
        } catch (error) {
            setResult([]);
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRemove = async (id) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/shortages/${id}`);

            setResult(result.filter((i) => i.id !== id));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        if (!dialogOpen) {
            getShortages();
        }
    }, [type, dialogOpen]);

    const headers =
        type === 'notExisting'
            ? [text.REMOVE, text.DESCRIPTION, text.SHOP, text.DATE]
            : [
                  text.REMOVE,
                  text.CODE,
                  text.BRAND,
                  text.COLOR,
                  text.MODEL,
                  text.SHOP,
                  'dane sprz. z ost. 7 dni',
                  text.DATE,
              ];

    const tableRow =
        type === 'notExisting'
            ? (row) => (
                  <TableRow hover key={row.id}>
                      <TableCell align="center">
                          <IconButton onClick={() => handleClickRemove(row.id)}>
                              <DeleteOutlineIcon color="error" />
                          </IconButton>
                      </TableCell>
                      <TableCell align="center">{row.customProduct}</TableCell>
                      <TableCell align="center">
                          <ColoringShop shopId={row.shop_id}>
                              {row.shop_name}
                          </ColoringShop>
                      </TableCell>
                      <TableCell align="center">
                          {moment(row.createdAt).format('YYYY-MM-DD')}
                      </TableCell>
                  </TableRow>
              )
            : (row) => (
                  <TableRow hover key={row.id}>
                      <TableCell align="center">
                          <IconButton onClick={() => handleClickRemove(row.id)}>
                              <DeleteOutlineIcon color="error" />
                          </IconButton>
                      </TableCell>
                      <TableCell align="center">{row.product_code}</TableCell>
                      <TableCell align="center">{row.product_brand}</TableCell>
                      <TableCell align="center">{row.product_color}</TableCell>
                      <TableCell align="center">{row.product_type}</TableCell>
                      <TableCell align="center">
                          <ColoringShop shopId={row.shop_id}>
                              {row.shop_name}
                          </ColoringShop>
                      </TableCell>
                      <TableCell align="center">
                          <TrendBlock
                              difference={
                                  row.countOrdersLastWeek -
                                  row.countOrdersLastTwoWeek
                              }
                          />
                      </TableCell>
                      <TableCell align="center">
                          {moment(row.createdAt).format('YYYY-MM-DD')}
                      </TableCell>
                  </TableRow>
              );

    return (
        <ViewContentCard title={text.SHORTAGES}>
            <Grid
                container
                alignItems="center"
                spacing={1}
                className={classes.container}
            >
                <Grid item xs={12} md={5} lg={2}>
                    <Button
                        fullWidth
                        onClick={() => history.push('/braki/dodaj')}
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                    >
                        Dodaj braki
                    </Button>
                </Grid>
                <Grid item xs={12} md={5} lg={3}>
                    <Button
                        fullWidth
                        onClick={() => setDialogOpen(true)}
                        variant="contained"
                        color="secondary"
                        className={classes.btn}
                    >
                        Dodaj niestandardowy produkt do braków
                    </Button>
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={type === 'notExisting'}
                                onChange={(e) => {
                                    setResult([]);
                                    setType(
                                        e.target.checked
                                            ? 'notExisting'
                                            : 'existing'
                                    );
                                }}
                            />
                        }
                        label="Niestandardowe braki"
                    />
                </Grid>
            </Grid>
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={headers}
                    rowRender={tableRow}
                    rowsData={result}
                />


            )}
            <CustomShortageDialog
                open={dialogOpen}
                handleClose={() => setDialogOpen(false)}
            />
        </ViewContentCard>
    );
};

export default Shortages;
