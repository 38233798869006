// @flow
import React from 'react';
import {
    Grid,
    ButtonGroup,
    Button,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { selectShops } from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import type { Shop } from "../helpers/interface";
import { shopName } from "../constants/strings";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'center',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

type Props = { direction: 'from' | 'to' };

const ShopTransferSwitcher = ({ direction }: Props) => {
    const shops = useSelector((state) => selectShops(state));
    const user = useSelector((state) => selectUser(state));
    const classes = useStyles();

    const generateUrl = (shopId: number): string => {
        const from = direction === 'from' ? shopId : user.shopId;
        const to = direction === 'from' ? user.shopId : shopId;

        return `/przerzut-butow/${from}/${to}`;
    };

    return (
        <Grid container justify="center">
            <ButtonGroup
                className={classes.root}
                orientation="horizontal"
                color="primary"
                variant="contained"
                size="large"
            >
                {shops
                    .filter((shop: Shop) => shop.id !== user.shopId && shop.short !== 'si')
                    .map((shop: Shop) => (
                        <Button
                            key={shop.id}
                            to={() => generateUrl(shop.id)}
                            component={NavLink}
                            style={{
                                color:
                                    shop.name === shopName.KRUPOWKI ? '#000' : '#fff',
                                borderColor: shop.color,
                                backgroundColor: shop.color,
                                }}
                        >
                            {shop.name}
                        </Button>
                    ))}
            </ButtonGroup>
        </Grid>
    );
};

export default ShopTransferSwitcher;
