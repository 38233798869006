// @flow
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemText,
    makeStyles,
} from '@material-ui/core';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { sendAuthAjax } from 'helpers/authorization';

const StyleBadge = styled.span`
    background-color: ${({ bgcolor }) => bgcolor};
    width: 20px;
    height: 20px;
    text-align: center;
    margin-right: 5px;
    border-radius: 50%;
    color: #fff;
`;

const useStyles = makeStyles({
    root: {
        height: '100%',
    },
    image: {
        height: 48,
        width: 48,
    },
});

const ShopsRanking = ({
    className,
    title,
    dateFrom,
    dateTo,
    limit = 0,
    ...rest
}: {
    className: string,
    title: string,
    dateFrom: string,
    dateTo: string,
    limit: int,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [result, setResult] = useState([]);

    const getRanking = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/shops/ranking?dateFrom=${dateFrom}&dateTo=${dateTo}&limit=${limit}`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getRanking();
    }, []);

    return (
        <Card className={clsx(classes.root, className)} {...rest}>
            <CardHeader title={title} />
            <Divider />
            <List>
                {result.map((shop, i) => (
                    <ListItem divider={i < result.length - 1} key={i}>
                        <StyleBadge bgcolor={`rgb(${67 + i * 40}, 160, 71)`}>
                            {i + 1}
                        </StyleBadge>
                        <ListItemText primary={shop.name} />
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};

export default ShopsRanking;
