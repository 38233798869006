// @flow
import React, { useEffect, useState } from 'react';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch } from 'react-redux';
import { addAlert, setIsLoading } from 'context/features/global/globalSlice';
import moment from 'moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import CollapsedTable from 'components/CollapsedTable';
import CustomDay from 'components/CustomDay';

const useStyles = makeStyles({
    root: {
        marginTop: '8px',
    },
});

const StatementProductDetail = () => {
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [result, setResult] = useState({
        additions: {
            details: [],
            total: { sum: 0, sizes: [] },
        },
        exchanges: {
            details: [],
            total: { sum: 0, sizes: [] },
        },
        inventories: {
            details: [],
            total: { sum: 0, sizes: [] },
        },
        orders: {
            details: [],
            total: { sum: 0, sizes: [] },
        },
    });
    const [additionDays, setAdditionDays] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    const classes = useStyles();

    const createShoeBlockHeader = (shoe: object) => {
        if (typeof shoe === 'object') {
            return `${shoe.brand} ${shoe.code} ${shoe.color} ${shoe.type}`;
            // return `${response.brand} ${response.code} ${response.color} ${response.type}`;
        }
        return '';
    };

    const getAdditionalDays = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/addition-days?productId=${id}`
            );
            setAdditionDays(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getStatement = async (all = false) => {
        if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
            dispatch(
                addAlert({
                    content: 'Nieprawidłowy przedział czasowy',
                    type: 'error',
                })
            );
            return;
        }
        let from = moment(fromDate).format('YYYY-MM-DD');
        if (all) from = moment('2000-01-01').format('YYYY-MM-DD')
        const to = moment(toDate).format('YYYY-MM-DD');

        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/statement/${id}/addition-sales?dateFrom=${from}&dateTo=${to}`
            );
            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickSearch = async () => {
        getStatement();
    };

    useEffect(() => {
        getAdditionalDays();
        getStatement(true);
    }, []);

    return (
        <ViewContentCard title={text.STATEMENT_PRODUCT}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid
                    className={classes.root}
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            autoOk
                            fullWidth
                            label="Od"
                            size="small"
                            format="YYYY-MM-DD"
                            variant="inline"
                            inputVariant="outlined"
                            value={fromDate}
                            onChange={(date) => setFromDate(date)}
                            renderDay={(
                                day,
                                selectedDate,
                                dayInCurrentMonth,
                                dayComponent
                            ) => (
                                <CustomDay
                                    additionDays={additionDays}
                                    {...dayComponent.props}
                                    day={day}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                        <KeyboardDatePicker
                            autoOk
                            fullWidth
                            label="Do"
                            size="small"
                            format="YYYY-MM-DD"
                            variant="inline"
                            inputVariant="outlined"
                            value={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            renderDay={(
                                day,
                                selectedDate,
                                dayInCurrentMonth,
                                dayComponent
                            ) => (
                                <CustomDay
                                    additionDays={additionDays}
                                    {...dayComponent.props}
                                    day={day}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} lg={1}>
                        <Button
                            fullWidth
                            onClick={handleClickSearch}
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            szukaj
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <h2>{createShoeBlockHeader(result.product)}</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsedTable
                            title="Dodania"
                            details={result.additions.details}
                            total={result.additions.total.sum}
                            totalSizes={result.additions.total.sizes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsedTable
                            title="Sprzedaż"
                            details={result.orders.details}
                            total={result.orders.total.sum}
                            totalSizes={result.orders.total.sizes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsedTable
                            title="Stan magazynowy"
                            details={result.inventories.details}
                            total={result.inventories.total.sum}
                            totalSizes={result.inventories.total.sizes}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CollapsedTable
                            title="Wymiany/Wydania"
                            details={result.exchanges.details}
                            total={result.exchanges.total.sum}
                            totalSizes={result.exchanges.total.sizes}
                            hasTotalRow={false}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </ViewContentCard>
    );
};

export default StatementProductDetail;
