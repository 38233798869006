import { AxiosError } from 'axios';
import { sendAuthAjax } from 'helpers/authorization';
import { ChecklistReportAverageRating } from 'helpers/interface';
import { useQuery } from 'react-query';

export const key = 'checklistReportsAverageRatingsQuery';
const path = '/checklist-reports/average-ratings';

const getChecklistReportsAverageRatings = async () => {
    const { data } = await sendAuthAjax('get', path);
    return data;
};

export default function useChecklistReportsAverageRatingsQuery() {
    return useQuery<ChecklistReportAverageRating[], AxiosError>(
        key,
        () => getChecklistReportsAverageRatings(),
    );
}
