// @flow
import React from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import {
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert, selectShops } from 'context/features/global/globalSlice';
import ChecklistCategory from '../components/Checklist/ChecklistCategory';
import { cloneDeep, map, sortBy } from 'lodash';
import { ChecklistCategory as ChecklistCategoryInterface, ChecklistItem as ChecklistItemInterface, ChecklistReport, Shop, ChecklistReportItem, ChecklistReportUpdateDto } from 'helpers/interface';
import { Controller, useForm } from 'react-hook-form';
import useAddChecklistReportMutation from 'hooks/useAddChecklistReportMutation';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers';
import { StyledTableCell } from 'styles/components';
import { useHistory, useParams } from 'react-router-dom';
import useChecklistReportQuery from 'hooks/useChecklistReportQuery';
import useUpdateChecklistReportMutation from 'hooks/useUpdateChecklistReportMutation';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { key as checklistReportQueryKey } from 'hooks/useChecklistReportQuery';
import { key as checklistReportsQueryKey } from 'hooks/useChecklistReportsQuery';
import { key as checklistReportsAverageRatingsQueryKey } from 'hooks/useChecklistReportsAverageRatingsQuery';
import moment from 'moment';
import useChecklistCategoriesQuery from 'hooks/useChecklistCategoriesQuery';

const useStyles = makeStyles(() => ({
    reportFieldName: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        fontSize: '16px'
    },
    reportField: {
        marginTop: '10px',
        marginBottom: '10px',
    }
}));

type Params = {
    id: string;
};

type Props = {
    mode: Modes;
};

export enum Modes {
    add = 0,
    edit = 1,
    show = 2
}

const ChecklistCreate = ({ mode }: Props) => {
    const { id } = useParams<Params>();
    const shopList = useSelector((state) => selectShops(state)) as Shop[];
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const queryClient = useQueryClient();

    const { control: checklistFormControl, handleSubmit: checklistFormHandleSubmit} = useForm();
    const { control: reportFormControl , handleSubmit: reportFormHandleSubmit } = useForm();

    const { mutate: addChecklistReportMutation } = useAddChecklistReportMutation();
    const { mutate: updateChecklistReportMutation } = useUpdateChecklistReportMutation();

    const apiErrorHandler = (err: AxiosError<any>) => {
        const errorText = err?.response?.data?.message
        ? err?.response?.data?.message
        : err?.response?.statusText;

        dispatch(
            addAlert({ content: errorText, type: 'error' })
        );
    }

    const createChecklistReport = (report: ChecklistReport) => {
        const payload = {
            checklist_report_items: report.checklist_report_items
        };

        addChecklistReportMutation(
            payload,
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(checklistReportsQueryKey);
                    queryClient.invalidateQueries(checklistReportsAverageRatingsQueryKey);
                    history.push('/checklista/wypelnij/' + data.id);

                    dispatch(
                        addAlert({
                            content: 'Poprawnie dodano',
                            type: 'success',
                        })
                    );
                },
                onError: apiErrorHandler,
            }
        );
    }

    const updateChecklistReport = (payload: ChecklistReportUpdateDto, redirectToList: boolean) => {
        updateChecklistReportMutation(
            payload,
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries([checklistReportQueryKey, data.id?.toString()]);
                    queryClient.invalidateQueries(checklistReportsQueryKey);
                    queryClient.invalidateQueries(checklistReportsAverageRatingsQueryKey);

                    if(redirectToList) {
                        history.push('/checklista');
                    }

                    dispatch(
                        addAlert({
                            content: 'Poprawnie zaktualizowano',
                            type: 'success',
                        })
                    );
                },
                onError: apiErrorHandler,
            }
        );
    }

    const handleChecklistFormSave = async () => {
        checklistFormHandleSubmit((formData) => {
            let report: ChecklistReport = {
                checklist_report_items: [],
            };

            if (mode === Modes.edit) {
                report = checklistReportQuery.data as ChecklistReport;
            }

            checklistCategoriesQuery.data?.forEach((category: ChecklistCategoryInterface) => {
                category.checklist_items?.forEach((item: ChecklistItemInterface) => {
                    const checked = formData[`checkbox-${category.id}-${item.id}`];
                    const selectedOptionId = formData[`select-${category.id}-${item.id}`];

                    let checklistItemOption;

                    if (selectedOptionId) {
                        checklistItemOption = item.checklist_item_options.find((element) => selectedOptionId === element.id);
                    }

                    if (checked === true || (checklistItemOption && !checklistItemOption.needs_improvement)) {
                        report.checklist_report_items = report.checklist_report_items.filter((element) => element.checklist_item.id !== item.id);
                        return;
                    }

                    const comment = formData[`comment-${category.id}-${item.id}`];

                    let checklistReportItem = report.checklist_report_items.find((element) => element.checklist_item.id === item.id);

                    if (checklistReportItem) {
                        checklistReportItem.comment = comment;

                        if (checklistItemOption) {
                            checklistReportItem.checklist_item_option = checklistItemOption;
                        }
                    } else {
                        checklistReportItem = {
                            checklist_item: item,
                            comment: comment,
                        };

                        if (checklistItemOption) {
                            checklistReportItem.checklist_item_option = checklistItemOption;
                        }

                        report.checklist_report_items.push(checklistReportItem);
                    }

                });
            });

            if (mode === Modes.add) {
                createChecklistReport(report);
            } else {
                const payload = {
                    id: report.id as number,
                    checklist_report_items: report.checklist_report_items
                };

                updateChecklistReport(payload, false);
            }
        })();
    };

    const handleReportFormSave = async () => {
        reportFormHandleSubmit((formData) => {
            const report = checklistReportQuery.data as ChecklistReport;

            report.checklist_report_items.forEach(element => {
                const implementationDate = formData[`implementation-date-${element.id}`];
                element.implementation_date = implementationDate;
            });

            const reportDate = moment(formData['date']);
            const time = moment(formData['time']);
            reportDate.set('hour', time.get('hour'))
            reportDate.set('minute', time.get('minute'))
            reportDate.set('second', time.get('second'))

            const payload: ChecklistReportUpdateDto = {
                id: report.id as number,
                report_date: reportDate.toDate(),
                shop_id: formData['shop'],
                shop_rating: formData['rating'],
                summary: formData['summary'],
                turnover_on_inspection_day: formData['turnover'],
                checklist_report_items: report.checklist_report_items
            };
           
            updateChecklistReport(payload, true);
        })();
    }

    const getReportItemName = (reportItem: ChecklistReportItem) => {
        let text = reportItem.checklist_item.name;

        if (reportItem.checklist_item_option) {
            text += ' : ' + reportItem.checklist_item_option.name;
        }

        return text;
    }

    const checklistCategoriesQuery = useChecklistCategoriesQuery();
    const checklistReportQuery = useChecklistReportQuery(id);

    let form: ChecklistCategoryInterface[] = [];

    if (mode === Modes.add && checklistCategoriesQuery.isSuccess) {
        form = cloneDeep(checklistCategoriesQuery.data);
        form = sortBy(form, ['position']);
    }

    if (mode === Modes.edit && checklistCategoriesQuery.isSuccess && checklistReportQuery.isSuccess) {
        form = cloneDeep(checklistCategoriesQuery.data);
        form = sortBy(form, ['position']);
        
        form.forEach((category: ChecklistCategoryInterface) => {
            category.checklist_items?.forEach((item: ChecklistItemInterface) => {
                const reportItem = checklistReportQuery.data.checklist_report_items.find((element) => element.checklist_item.id === item.id);

                if (!reportItem) {
                    if(!item.has_custom_options) {
                        item.checked = true;
                    }

                    return;
                }

                item.comment = reportItem.comment;

                if (item.has_custom_options && reportItem.checklist_item_option) {
                    item.selected_option_id = reportItem.checklist_item_option.id;
                } else {
                    item.checked = false;
                }
            });
        });
    }

    return (
        <ViewContentCard title={text.CHECKLIST}>
            <Container maxWidth="lg">
                {checklistReportQuery?.isSuccess && (
                    <>
                        <Card style={{ marginTop: '10px' }}>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom variant="h5" style={{ textAlign: 'center' }}>
                                    Raport
                                </Typography>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Sklep
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                    <Controller
                                        rules={{ required: 'To pole jest wymagane' }}
                                        defaultValue={checklistReportQuery.data.shop?.id ? checklistReportQuery.data.shop.id : ''}
                                        name="shop"
                                        control={reportFormControl}
                                        render={({ field, fieldState: { error } }) => (
                                            <FormControl
                                                fullWidth
                                                error={!!error?.message}
                                                required
                                            >
                                                <Select
                                                    input={<Input />}
                                                    disabled={mode === Modes.show}
                                                    {...field}
                                                >
                                                    {map(
                                                        shopList,
                                                        (shop) => (
                                                            <MenuItem
                                                                key={shop.id}
                                                                value={shop.id}
                                                            >
                                                                {shop.name}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>

                                                {error?.message && (
                                                    <FormHelperText
                                                        error={!!error?.message}
                                                    >
                                                        {error?.message}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        )}
                                    />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Data
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            rules={{ required: 'To pole jest wymagane' }}
                                            defaultValue={checklistReportQuery.data.report_date ? checklistReportQuery.data.report_date : new Date()}
                                            name="date"
                                            control={reportFormControl}
                                            render={({ field, fieldState: { error } }) => (
                                                <KeyboardDatePicker
                                                    helperText={error?.message}
                                                    error={!!error?.message}
                                                    required
                                                    fullWidth
                                                    disabled={mode === Modes.show}
                                                    size="small"
                                                    variant="dialog"
                                                    format="YYYY-MM-DD"
                                                    inputVariant="standard"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Godzina kontroli
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            rules={{ required: 'To pole jest wymagane' }}
                                            name="time"
                                            defaultValue={checklistReportQuery.data.report_date ? checklistReportQuery.data.report_date : new Date()}
                                            control={reportFormControl}
                                            render={({ field, fieldState: { error } }) => (
                                                <TimePicker
                                                    helperText={error?.message}
                                                    error={!!error?.message}
                                                    required
                                                    disabled={mode === Modes.show}
                                                    ampm={false}
                                                    fullWidth
                                                    cancelLabel="Anuluj"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Obrót w dniu kontroli
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            rules={{
                                                required: 'To pole jest wymagane',
                                                maxLength: {
                                                    value: 20,
                                                    message:
                                                        'Maksymalna długość wynosi 20 znaków',
                                                },
                                            }}
                                            name="turnover"
                                            defaultValue={checklistReportQuery.data.turnover_on_inspection_day ? checklistReportQuery.data.turnover_on_inspection_day : ''}
                                            control={reportFormControl}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    required
                                                    helperText={error?.message}
                                                    error={!!error?.message}
                                                    fullWidth
                                                    disabled={mode === Modes.show}
                                                    size="small"
                                                    variant="standard"
                                                    type="number"
                                                    label="zł"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell >
                                                        Lp.
                                                    </StyledTableCell>
                                                    <StyledTableCell colSpan={2}>
                                                        Uwagi/zadania/elementy do poprawy lub wykonania
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        Data realizacji
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {map(
                                                    checklistReportQuery.data?.checklist_report_items.slice().sort((a: ChecklistReportItem, b: ChecklistReportItem) => {
                                                        if (!a.checklist_item.checklist_category?.position || !b.checklist_item.checklist_category?.position) {
                                                            return 0;
                                                        }
                                                        if (a.checklist_item.checklist_category.position < b.checklist_item.checklist_category.position ) {
                                                            return -1;
                                                        }
                                                        if (a.checklist_item.checklist_category.position > b.checklist_item.checklist_category.position ) {
                                                            return 1;
                                                        }
                                                        if (a.checklist_item.position < b.checklist_item.position ) {
                                                            return -1;
                                                        }
                                                        if (a.checklist_item.position > b.checklist_item.position ) {
                                                            return 1;
                                                        }
                                                        return 0;
                                                    }),
                                                    (item: ChecklistReportItem, index: number) => (
                                                        <TableRow 
                                                            key={item.id}
                                                        >
                                                            <TableCell align="center">{index}</TableCell>
                                                            <TableCell align="center">{getReportItemName(item)}</TableCell>
                                                            <TableCell align="center">{item.comment}</TableCell>
                                                            <TableCell align="center">
                                                            <Controller
                                                                rules={{ required: 'To pole jest wymagane' }}
                                                                name={`implementation-date-${item.id}`}
                                                                defaultValue={item.implementation_date ? item.implementation_date : new Date()}
                                                                control={reportFormControl}
                                                                render={({ field, fieldState: { error } }) => (
                                                                    <KeyboardDatePicker
                                                                        helperText={error?.message}
                                                                        error={!!error?.message}
                                                                        required
                                                                        disabled={mode === Modes.show}
                                                                        fullWidth
                                                                        size="small"
                                                                        variant="dialog"
                                                                        format="YYYY-MM-DD"
                                                                        inputVariant="standard"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Ogólna ocena
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            rules={{
                                                required: 'To pole jest wymagane',
                                                maxLength: {
                                                    value: 20,
                                                    message:
                                                        'Maksymalna długość wynosi 255 znaków',
                                                },
                                            }}
                                            name="rating"
                                            defaultValue={checklistReportQuery.data.shop_rating ? checklistReportQuery.data.shop_rating : ''}
                                            control={reportFormControl}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    required
                                                    type="number"
                                                    helperText={error?.message}
                                                    error={!!error?.message}
                                                    fullWidth
                                                    disabled={mode === Modes.show}
                                                    size="small"
                                                    variant="standard"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1} justify="flex-start" className={classes.reportField}>
                                    <Grid item xs={12} md={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                            className={classes.reportFieldName}
                                        >
                                            Podsumowanie
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Controller
                                            rules={{
                                                required: 'To pole jest wymagane',
                                                maxLength: {
                                                    value: 255,
                                                    message:
                                                        'Maksymalna długość wynosi 255 znaków',
                                                },
                                            }}
                                            name="summary"
                                            defaultValue={checklistReportQuery.data.summary ? checklistReportQuery.data.summary : ''}
                                            control={reportFormControl}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    required
                                                    helperText={error?.message}
                                                    error={!!error?.message}
                                                    fullWidth
                                                    disabled={mode === Modes.show}
                                                    size="small"
                                                    variant="standard"
                                                    multiline
                                                    rows={4}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {(mode === Modes.add || mode === Modes.edit) && (
                            <Button
                                fullWidth
                                onClick={handleReportFormSave}
                                variant="contained"
                                color="primary"
                            >
                                Zapisz Raport
                            </Button>
                        )}
                    </>
                )}
            </Container>
            {((mode === Modes.add || mode === Modes.edit) && form.length > 0) && (
                <>
                    <Container maxWidth="lg">
                        {map(
                            form,
                            (category: ChecklistCategoryInterface) => (
                                <ChecklistCategory
                                    category={category}
                                    key={category.id}
                                    control={checklistFormControl}
                                    mode={mode}
                                />
                            )
                        )}
                        <Button
                            fullWidth
                            onClick={handleChecklistFormSave}
                            variant="contained"
                            color="primary"
                        >
                            {mode as Modes === Modes.add ? ('Generuj Raport') : ('Aktualizuj pozycje raportu')}
                        </Button>
                    </Container>
                </>
            )}
        </ViewContentCard>
    );
};

export default ChecklistCreate;
