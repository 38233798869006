export const getSalesFilters = (filters) => {
    const searchParams = new URLSearchParams();

    if (filters.dateFrom) {
        searchParams.append('dateFrom', filters.dateFrom.format('YYYY-MM-DD'));
    }

    if (filters.dateTo) {
        searchParams.append('dateTo', filters.dateTo.format('YYYY-MM-DD'));
    }

    if (filters.group) {
        searchParams.append('group', filters.group);
    }

    if (filters.model) {
        searchParams.append('model', filters.model);
    }

    if (filters.shopId) {
        searchParams.append('shopId', filters.shopId);
    }

    if (filters.color) {
        searchParams.append('color', filters.color);
    }

    return searchParams;
};
